import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { Form, Formik } from "formik";

// select

import * as Yup from "yup";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getState } from "../../../../redux/features/slices/user/UserService";
import { useNavigate } from "react-router-dom";
import PagesIndex from "../../../../component/PagesIndex";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "4%",
  border: "none",
};

const Register = (props) => {
  const { handleClose, handleOpen, open } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // select
  const [stateList, setStateList] = React.useState([]);

  // password
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // radio
  const [radioValue, setRadioValue] = useState("User");
  const handleRadioChange = (newValue, e) => {
    setRadioValue(e);
  };
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    dob: "",

    addressLine1: "",
    addressLine2: "",

    state1: "",
    city1: "",
    pincode: "",

    // state2: "",
    // city2: "",
    // pinCode2: "",
  };

  const startSpace = /^(?!\s).*$/;
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("Please enter your first name")
      .matches(startSpace, "Starting space not allowed")
      .matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/, "Space allowed between characters")
      .max(100, "Accept only 100 characters")
      .matches(/\S$/, "Ending space not allowed"),
    lastName: Yup.string()
      .required("Please enter your last name")
      .matches(startSpace, "Starting space not allow")
      .matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/, "Space allowed between characters")
      .max(100, "Accept only 100 characters")
      .matches(/\S$/, "Ending space not allowed"),
    // firstName: Yup.string()
    //   .matches(/^[a-zA-Z\s-, ]+$/, "Accept only alphabetic and white space")
    //   .max(10,"Accept only 100 characters")
    //   .required("Please enter your first name"),
    // lastName: Yup.string()
    //   .matches(/^[a-zA-Z\s-, ]+$/, "Accept only alphabetic and white space")
    //   .required("Please enter your last name"),
    email: Yup.string()
      .email("Please enter your valid email")
      .matches(
        /^[a-z][a-z0-9]*(?:\.[a-z]+)*@[a-z]+\.[a-z]{2,}$/,
        "Please enter your valid email"
      )
      .required("Please enter your email address"),
    password: Yup.string()
      .required("Please enter your password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/,
        "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
      ),

    dob: Yup.string().required("Please select your date of birth"),
    // address 1
    state1: Yup.string().required("Please enter your state"),
    city1: Yup.string().required("Please enter your city"),
    pincode: Yup.string().required("Please enter your pin code"),
    addressLine1: Yup.string().required("Please enter your address line 1"),
    addressLine2: Yup.string().required("Please enter your address line 2"),
    // // address 2
    // state2: Yup.string().required("Please enter your state"),
    // city2: Yup.string().required("Please enter your city"),
    // pinCode2: Yup.string().required("Please enter your pin code"),
  });

  const getAllState = async () => {
    await DataService.get(API.User.GET_ALL_STATE).then((res) => {
      // await dispatch(getState()).then((res) => {
      console.log(res, 112);
      setStateList(res?.data?.data);
    });
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    console.log(values, "values");
    const data = new URLSearchParams();
    data.append("firstName", values.firstName);
    data.append("lastName", values.lastName);
    data.append("email", values.email);
    data.append("password", values.password);
    data.append("dateOfBirth", values.dob);
    data.append("role", "Notary");
    // data.append("role", radioValue);
    // Address Line 1
    // const addressLine1 = {
    //   city: values.city1,
    //   state: values.state1,
    //   pinCode: values.pinCode1,
    // };
    // data.append("address_Line_1", JSON.stringify(addressLine1));

    data.append("address_Line_1", values.addressLine1);
    data.append("address_Line_2", values.addressLine2);

    data.append("city", values.city1);
    data.append("state", values.state1);
    data.append("pincode", values.pincode);
    // Address Line 2
    // const addressLine2 = {
    //   city: values.city2,
    //   state: values.state2,
    //   pinCode: values.pinCode2,
    // };
    // data.append("address_Line_2", JSON.stringify(addressLine2));

    await DataService.post(API.User.USER_REGISTER, data)
      .then((res) => {
        if (res?.data?.status == 201) {
          toast.success(res?.data?.message);
          resetForm();
          navigate("/");
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    getAllState();
  }, []);
  return (
    <>
      {/* <Index.Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    > */}
      <Index.Box >
      <Index.Box className="admin-main">
      <Index.Box className="auth-main">
        <Index.Box className="auth-left">
          {/* <PagesIndex.Svg.CompanyLogo /> */}
        </Index.Box>
        <Index.Box className="auth-right">
          <Index.Box
            className="auth-common-box register-common-box">
            <Index.Box className="auth-header">
              <Index.Box
                className="auth-title"
                sx={{ textAlign: "center", fontWeight: 700, fontSize: 20 }}
              >
                Register
              </Index.Box>
            </Index.Box>
            <Formik
              initialValues={initialValues}
              onSubmit={handleFormSubmit}
              validationSchema={validationSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                setFieldValue,
                handleSubmit,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {console.log(values, "values178")}
                  <Index.Box className="auth-body">
                    <Index.FormControl className="radio-form-control">
                      {/* <Index.RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="User"
                        name="radio-buttons-group"
                        className="radio-group-main"
                        value={radioValue}
                        onChange={handleRadioChange}
                      >
                        <Index.FormControlLabel
                          className="form-control-label"
                          value="User"
                          control={<Index.Radio />}
                          label="User"
                        />
                        <Index.FormControlLabel
                          className="form-control-label"
                          value="Notary"
                          control={<Index.Radio />}
                          label="Notary"
                        />
                      </Index.RadioGroup> */}
                    </Index.FormControl>
                    <Index.Grid container spacing={3}>
                      <Index.Grid item xs={12}>
                        <Index.Box className="form-group">
                          <Index.FormHelperText className="form-lable">
                            First Name
                          </Index.FormHelperText>
                          <Index.TextField
                            fullWidth
                            type="text"
                            id="firstName"
                            name="firstName"
                            className="form-input"
                            placeholder="Please enter your first name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.firstName}
                          />
                        </Index.Box>
                        {errors.firstName && touched.firstName && (
                          <p className="error-text">{errors.firstName}</p>
                        )}
                      </Index.Grid>
                      <Index.Grid item xs={12}>
                        <Index.Box className="form-group">
                          <Index.FormHelperText className="form-lable">
                            Last Name
                          </Index.FormHelperText>
                          <Index.TextField
                            fullWidth
                            type="text"
                            id="lastName"
                            name="lastName"
                            className="form-input"
                            placeholder="Please enter your last name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lastName}
                          />
                        </Index.Box>
                        {errors.lastName && touched.lastName && (
                          <p className="error-text">{errors.lastName}</p>
                        )}
                      </Index.Grid>

                      <Index.Grid item xs={12}>
                        <Index.Box className="form-group">
                          <Index.FormHelperText className="form-lable">
                            Email
                          </Index.FormHelperText>
                          <Index.TextField
                            fullWidth
                            type="text"
                            id="email"
                            name="email"
                            className="form-input"
                            placeholder="Please enter your email"
                            onChange={(e) => {
                              setFieldValue("email", e.target.value.trim());
                            }}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                        </Index.Box>
                        {errors.email && touched.email && (
                          <p className="error-text">{errors.email}</p>
                        )}
                      </Index.Grid>
                      <Index.Grid item xs={12}>
                        <Index.Box className="form-group">
                          <Index.FormHelperText className="form-lable">
                            Password
                          </Index.FormHelperText>
                          <Index.OutlinedInput
                            fullWidth
                            className="form-input"
                            placeholder="Please enter your password"
                            autocomplete="off"
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={values?.password}
                            onChange={(e) => {
                              setFieldValue("password", e.target.value.trim());
                            }}
                            onBlur={handleBlur}
                            endAdornment={
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                          />
                        </Index.Box>
                        {errors.password && touched.password && (
                          <p className="error-text">{errors.password}</p>
                        )}
                      </Index.Grid>
                      <Index.Grid item xs={12}>
                        <Index.Box className="form-group">
                          <Index.FormHelperText className="form-lable">
                            Date of birth
                          </Index.FormHelperText>
                          <Index.TextField
                            fullWidth
                            type="date"
                            id="dob"
                            name="dob"
                            className="form-input"
                            placeholder="Please enter your date of birth"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.dob}
                          />
                        </Index.Box>
                        {errors.dob && touched.dob && (
                          <p className="error-text">{errors.dob}</p>
                        )}
                      </Index.Grid>

                      <Index.Grid item xs={12}>
                        <Index.Box className="form-group">
                          <Index.FormHelperText className="form-lable">
                            Address
                          </Index.FormHelperText>

                          <Index.Box className="form-group" sx={{ marginY: 1 }}>
                            <Index.TextField
                              fullWidth
                              type="text"
                              id="addressLine1"
                              name="addressLine1"
                              className="form-input"
                              placeholder="Address line 1"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.addressLine1}
                            />
                          </Index.Box>
                          {errors.addressLine1 && touched.addressLine1 && (
                            <p className="error-text">{errors.addressLine1}</p>
                          )}

                          <Index.Box className="form-group" sx={{ marginY: 1 }}>
                            <Index.TextField
                              fullWidth
                              type="text"
                              id="addressLine2"
                              name="addressLine2"
                              className="form-input"
                              placeholder="Address line 2"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.addressLine2}
                            />
                          </Index.Box>
                          {errors.addressLine1 && touched.addressLine1 && (
                            <p className="error-text">{errors.addressLine1}</p>
                          )}

                          <Index.Grid container xs={12} spacing={2}>
                          <Index.Grid item xs={4}>
                            <Index.Box sx={{ marginY: 1 }}>
                              <Index.TextField
                                fullWidth
                                type="text"
                                id="city1"
                                name="city1"
                                className="form-input"
                                placeholder="Enter your city"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.city1}
                              />
                              {errors.city1 && touched.city1 && (
                                <p className="error-text">{errors.city1}</p>
                              )}
                            </Index.Box>
                            </Index.Grid>
                            <Index.Grid item xs={4}>
                            <Index.FormControl sx={{ minWidth: "100%", marginY: 1 }}>
                            <Index.Box className='admin-dropdown-box'>
                              <Index.Select
                              className='admin-dropdown-select admin-drop-select' sx={{ minWidth: "100%" }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.state1}
                                name="state1"
                                displayEmpty
                                onChange={(e) => {
                                  const selectedValue = e.target.value;
                                  const selectedItem = stateList.find(
                                    (item) => item._id === selectedValue
                                  );
                                  setFieldValue("state1", selectedValue);
                                  setFieldValue(
                                    "state1Name",
                                    selectedItem?.name
                                  );
                                }}
                              >
                                <Index.MenuItem value="" disabled className='admin-drop-menuitem'>
                                  Select state
                                </Index.MenuItem>
                                {stateList?.map((item) => (
                                  <Index.MenuItem className='admin-drop-menuitem'
                                    value={item?._id}
                                    key={item?._id}
                                  >
                                    {item?.name}
                                  </Index.MenuItem>
                                ))}
                              </Index.Select>
                              {errors.state1 && touched.state1 && (
                                <p className="error-text">{errors.state1}</p>
                              )}
                              </Index.Box>
                            </Index.FormControl>
                            </Index.Grid>
                            <Index.Grid item xs={4}>
                            <Index.Box sx={{ marginY: 1 }}>
                              <Index.TextField
                                fullWidth
                                type="text"
                                id="pincode"
                                name="pincode"
                                className="form-input"
                                placeholder="Enter your pin code"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.pincode}
                              />
                              {errors.pincode && touched.pincode && (
                                <p className="error-text">{errors.pincode}</p>
                              )}
                            </Index.Box>
                            </Index.Grid>
                          </Index.Grid>
                        </Index.Box>
                      </Index.Grid>

                      {/* <Index.Grid item xs={12}>
                        <Index.Box className="form-group">
                          <Index.FormHelperText className="form-lable">
                            Address Line 2
                          </Index.FormHelperText>

                          <Index.Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: 2,
                            }}
                          >
                            <Index.Box sx={{ minWidth: 150 }}>
                              <Index.Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.state2}
                                name="state2"
                                displayEmpty
                                onChange={(e) => {
                                  const selectedValue = e.target.value;
                                  const selectedItem = stateList.find(
                                    (item) => item._id === selectedValue
                                  );

                                  setFieldValue("state2", selectedValue);
                                  setFieldValue(
                                    "state2Name",
                                    selectedItem?.name
                                  );
                                }}
                              >
                                <Index.MenuItem value="" disabled>
                                  Select state
                                </Index.MenuItem>
                                {stateList?.map((item) => (
                                  <Index.MenuItem
                                    value={item?._id}
                                    key={item?._id}
                                  >
                                    {item?.name}
                                  </Index.MenuItem>
                                ))}
                              </Index.Select>
                              {errors.state2 && touched.state2 && (
                                <p className="error-text">{errors.state2}</p>
                              )}
                            </Index.Box>
                            <Index.Box>
                              <Index.TextField
                                fullWidth
                                type="text"
                                id="city2"
                                name="city2"
                                className="form-input"
                                placeholder="Please enter your city"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.city2}
                              />
                              {errors.city2 && touched.city2 && (
                                <p className="error-text">{errors.city2}</p>
                              )}
                            </Index.Box>
                            <Index.Box>
                              <Index.TextField
                                fullWidth
                                type="text"
                                id="pinCode2"
                                name="pinCode2"
                                className="form-input"
                                placeholder="Please enter your pin code"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.pinCode2}
                              />
                              {errors.pinCode2 && touched.pinCode2 && (
                                <p className="error-text">{errors.pinCode2}</p>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid> */}
                      <Index.Grid item xs={12}>
                        <Index.Box className="auth-btn-box main-btn-sec">
                          <PagesIndex.Button
                            // className="register-btn"
                            primary
                            //   onClick={() => {
                            //     navigate("/admin/dashboard");
                            //   }}
                            type="submit"
                          >
                            Submit
                          </PagesIndex.Button>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>
                  </Index.Box>
                </Form>
              )}
            </Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
        </Index.Box>
      </Index.Box>
      {/* </Index.Modal> */}
    </>
  );
};

export default Register;
