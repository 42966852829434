import React, { useEffect } from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";
import { useDispatch } from "react-redux";
import { notaryLogout } from "../../../redux/features/slices/user/UserSlice";
import Button from "../../common/button/Button";
import LoadingButton from "@mui/lab/LoadingButton";
// import { useMyContext } from "../../../utils/GlobalContext";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
// import ethers from "ethers";
const ethers = require("ethers");

export default function NotaryHeader(props) {
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
  const navigate = PagesIndex.useNavigate();
  const dispatch = useDispatch();

  // const { connectWallet, connected, loading, address, balance } =
  //   useMyContext();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [signer, setSigner] = React.useState(null)
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  window.onresize = function resize() {
    if (window.innerWidth <= 1023) {
      props.setOpen(false);
    } else {
      document.body.classList["remove"]("body-overflow");
    }
  };

  // Function to connect/disconnect the wallet
  // async function connectWallet() {
  //   try {
  //     setLoading(true);
  //     setTimeout(() => {}, 5000);
  //     if (window.ethereum) {
  //       // res[0] for fetching a first wallet
  //       window.ethereum
  //         .request({ method: "eth_requestAccounts" })
  //         .then(async (res) => {
  //           setWalletAddress(res[0]);
  //           await getBalance(res[0]);
  //         });
  //       setConnected(true);
  //     } else {
  //       alert("install metamask extension!!");
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error, "MTMSK Error");
  //     alert("User Rejected to Connect Wallet");
  //     setLoading(false);
  //   }
  // }
  // const getBalance = async (address) => {
  //   setLoading(true);
  //   const provider = new ethers.providers.Web3Provider(window.ethereum);
  //   const balance = await provider.getBalance(address);
  //   const balanceInEth = ethers.utils.formatEther(balance);
  //   setBalance(balanceInEth);
  //   console.log(balanceInEth);
  //   setLoading(false);
  // };

  useEffect(() => {
    if (window.innerWidth <= 1023) {
      document.body.classList[props.open ? "add" : "remove"]("body-overflow");
    }
  }, [props.open]);

  const setSignerObj = async () => {
    if (isConnected) {
      const ethersProvider = new ethers.providers.Web3Provider(walletProvider)
      const signer = await ethersProvider.getSigner()
      setSigner(signer)
    }
  }

  const logOut = async () =>{
    try {
      if(props.user === "user"){
        localStorage.removeItem("phone");
        navigate('/')        
        return
      }
      dispatch(notaryLogout())
      navigate('/')        
    } catch (error) {
      console.log(error);      
    }
  } 

  React.useState(() => {

  }, [address]);

  return (
    <Index.Box className="admin-header">
      <Index.Box className="admin-header-left">
        <PagesIndex.Button
          className="nav-menu-btn"
          onClick={() => {
            props.setOpen(!props.open);
          }}
        >
          <PagesIndex.Svg.HumburgerIcon />
        </PagesIndex.Button>
        {/* <PagesIndex.Svg.CompanyLogo /> */}
      </Index.Box>
      <Index.Box className="admin-header-right">
        {/* <PagesIndex.Button className="icon-box">
          <PagesIndex.Svg.NotificationIcon />
        </PagesIndex.Button>
        <Index.Box className="profile-drop-main">
          <PagesIndex.Button className="profile-drop-btn" onClick={handleClick}>
            <img
              src={PagesIndex.Png.profile}
              className="header-profile"
              alt="User"
            />
          </PagesIndex.Button>
          <Index.Menu
            className="profile-drop-menu"
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <Index.MenuItem
              onClick={() => {
                handleClose();
              }}
              className="profile-drop-menuitem"
            >
              Profile
            </Index.MenuItem>
            <Index.MenuItem
              onClick={() => {
                handleClose();
              }}
              className="profile-drop-menuitem"
            >
              Account Settings
            </Index.MenuItem>
            <Index.MenuItem
              onClick={() => {
                dispatch(notaryLogout());
                navigate("/");
                localStorage.removeItem("token");
                localStorage.removeItem("_id");
              }}
              className="profile-drop-menuitem"
            >
              Sign Out
            </Index.MenuItem>
          </Index.Menu>
        </Index.Box> */}
        <Index.Box className="admin-header-right">
        <PagesIndex.Button className="icon-box">
          <PagesIndex.Svg.NotificationIcon />
        </PagesIndex.Button>
        <Index.Box className="profile-drop-main">
          <PagesIndex.Button className="profile-drop-btn" onClick={handleClick}>
            <img
              src={PagesIndex.Png.profile}
              className="header-profile"
              alt="User"
            />
          </PagesIndex.Button>
          <Index.Menu
            className="profile-drop-menu"
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <Index.MenuItem
              onClick={() => {
                handleClose();
              }}
              className="profile-drop-menuitem"
            >
              Profile
            </Index.MenuItem>
            <Index.MenuItem
              onClick={() => {
                handleClose();
              }}
              className="profile-drop-menuitem"
            >
              Account Settings
            </Index.MenuItem>
            <Index.MenuItem
              onClick={() => {logOut()}}
              className="profile-drop-menuitem"
            >
              Sign Out
            </Index.MenuItem>
          </Index.Menu>
        </Index.Box>
      </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
