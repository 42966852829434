import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { getAllUsersNotaries } from "../../../../ApiHelpers/notaryCalls";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import download from "../../../../assets/images/svg/download.svg";
import { Link, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "../../../../component/common/button/Button";
import { Triangle } from "react-loader-spinner";
import Popover from "@mui/material/Popover";
import Index from "../../../Index";
import PagesIndex from "../../../../component/PagesIndex";

const UserNotaries = () => {
  const navigate = useNavigate();
  const [notaries, setNotaries] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [desc, setDesc] = React.useState(false);
  const [descData, setDescData] = React.useState("");
  const handleDescOpen = (_desc) => {
    setDescData(_desc);
    setDesc(true);
  };
  const handleDescClose = () => setDesc(false);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const _open = Boolean(anchorEl);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const loadAdvocateNotaries = async () => {
    setLoading(true);
    const _phone = localStorage.getItem("phone");
    if (_phone === undefined || _phone === null) {
      navigate("/");
      setLoading(false);
      return;
    }
    const allAdvocateNot = await getAllUsersNotaries(_phone);
    setNotaries(allAdvocateNot?.data?.data);
    console.log(allAdvocateNot?.data?.data, "allAdvocateNotallAdvocateNot");
    setLoading(false);
  };

  React.useEffect(() => {
    loadAdvocateNotaries();
  }, []);

  return (
    <Index.Box className="admin-main">
      <Index.Box className={`admin-main-left ${open ? "active" : "deactive"}`}>
        <PagesIndex.NotarySidebar open={open} setOpen={setOpen} />
      </Index.Box>
      <Index.Box className={`admin-main-right ${open ? "active" : "deactive"}`}>
        <PagesIndex.NotaryHeader setOpen={setOpen} open={open} user={"user"} />
        <Index.Box className="admin-content-main">
          <>
            {loading ? (
              <div className="loader-main">
                <div className="loader-sec">
                  <Triangle
                    visible={true}
                    height="80"
                    width="80"
                    color="#1976d2"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              </div>
            ) : (
              <div>
                <Box className="admin-user-list-flex admin-page-title-main">
                  <Typography
                    className="admin-page-title"
                    component="h2"
                    variant="h2"
                  >
                    My Notaries
                  </Typography>
                </Box>
                <Box className="table-wrapper card-border common-card">
                  <Box className="admin-userlist-table-main page-table-main">
                    <TableContainer
                      component={Paper}
                      className="table-container"
                    >
                      <Table aria-label="simple table" className="table">
                        <TableHead className="table-head">
                          <TableRow className="table-row">
                            <TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="10%"
                            >
                              Title
                            </TableCell>
                            <TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="10%"
                            >
                              Created On
                            </TableCell>
                            <TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="10%"
                            >
                              Description
                            </TableCell>
                            <TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="10%"
                            >
                              ACTION
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {notaries
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((_, index) => (
                            <TableBody className="table-body" key={notaries}>
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  <Box className="admin-table-data-flex">
                                    <Typography className="admin-table-data-text">
                                      {" "}
                                      {_?.title}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  <Box className="admin-table-data-flex">
                                    <Typography className="admin-table-data-text">
                                      {" "}
                                      {moment(_?.createdAt)?.format(
                                        "DD-MM-YYYY hh:mm A"
                                      )}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleDescOpen(_?.description);
                                  }}
                                  aria-owns={
                                    _open ? "mouse-over-popover" : undefined
                                  }
                                  aria-haspopup="true"
                                  onMouseEnter={handlePopoverOpen}
                                  onMouseLeave={handlePopoverClose}
                                >
                                  <Box>
                                    {" "}
                                    {_?.description?.slice(0, 50)}
                                    {/* {"..."}
                            {_?.description?.slice(
                              _?.description?.length - 3,
                              _?.description?.length
                            )} */}
                                    <Popover
                                      id="mouse-over-popover"
                                      sx={{
                                        pointerEvents: "none",
                                        width: "120px",
                                        height: "80px",
                                      }}
                                      open={_open}
                                      anchorEl={anchorEl}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                      }}
                                      onClose={handlePopoverClose}
                                      disableRestoreFocus
                                    >
                                      <Typography
                                        sx={{ p: 1, fontSize: "10px" }}
                                      >
                                        Click to expand.
                                      </Typography>
                                    </Popover>
                                  </Box>
                                </TableCell>
                                <TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  <Box className="admin-table-data-btn-flex ">
                                    <Link
                                      to={`https://ipfs.io/ipfs/${_?.ipfsHash}`}
                                      target="_blank"
                                    >
                                      <Button
                                        className="admin-table-data-btn"
                                        onClick={() => {}}
                                      >
                                        <img
                                          src={download}
                                          className="admin-icon"
                                          alt="Edit"
                                        ></img>
                                      </Button>
                                    </Link>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          ))}
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box className="admin-pagination-main">
                    <TablePagination
                      component="div"
                      count={notaries?.length}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Box>
                </Box>
              </div>
            )}
          </>
          {/* description modal starts */}
          <Dialog
            open={desc}
            onClose={handleDescClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="import-steps-modal"
            fullWidth
          >
            <DialogTitle id="alert-dialog-title" className="modal-title">
              {"Description"}
            </DialogTitle>
            <DialogContent className="modal-body">
              <Box id="alert-dialog-description" className="modal-content">
                <Typography variant="p" component="p">
                  {" "}
                  {descData}
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions className="p-0">
              <Box className="main-btn-sec">
                <Button primary onClick={handleDescClose}>
                  close
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
          {/* description modal ends */}
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default UserNotaries;
