import {
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  List,
  ListItem,
} from "@mui/material";
import { Link } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DownloadIcon from '@mui/icons-material/Download';
import TablePagination from '@mui/material/TablePagination';

export default {
  Box,
  Link,
  Typography,
  Button,
  Menu,
  MenuItem,
  List,
  ListItem,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  DownloadIcon,
  TablePagination
};
