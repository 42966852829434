import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "../component/user/defaultLayout/Layout";
import Login from "../container/admin/auth/login/Login";
import ForgotPassword from "../container/admin/auth/forgotPassword/ForgotPassword";
import OtpVerify from "../container/admin/auth/otpVerify/OtpVerify";
import ResetPassword from "../container/admin/auth/resetPassword/ResetPassword";
import PageNotFound from "../component/common/pageNotFound/PageNotFound";
import AdminLayout from "../component/admin/defaultLayout/AdminLayout";
import AdminDashboard from "../container/admin/pages/dashboard/AdminDashboard";
import Register from "../container/user/auth/userRegister/Register";
import UserDashboard from "../container/user/pages/dashboard/UserDashboard";
import NotaryLayout from "../component/notary/defaultLayout/NotaryLayout";
import NotaryDashboard from "../container/notary/pages/NotaryDashboard";
import UserLogin from "../container/user/auth/userLogin/UserLogin";
import UserPrivateRoutes from "./UserPrivateRoutes";
import NotaryPrivateRoutes from "./NotaryPrivateRoutes";
import UserForgotPassword from "../container/user/auth/forgotPassword/ForgotPassword";
import UserOtpVerify from "../container/user/auth/otpVerify/OtpVerify";
import UserResetPassword from "../container/user/auth/resetPassword/ResetPassword";
import AddNotary from "../component/notary/createNotary/index";
import Notaries from "../component/notary/notaryList/Index";
import NotaryDocument from "../container/user/pages/notatyDocument/NotaryDocument";
import UserLoginOTP from "../container/user/auth/userLogin/UserLoginOTP";
import ContactVerification from "../container/user/auth/contactNoVerify/ContactVerification";
import VerifyContactOTP from "../container/user/auth/contactNoVerify/VerifyContactOTP";
import UserNotaries from "../container/user/auth/userLogin/UserNotaries";
export default function Routers() {
  return (
    <BrowserRouter>
      <Routes>
        {/* user start */}
        <Route path="/">
          <Route path="" element={<UserLogin />} />
          <Route path="otp-login" element={<UserLoginOTP />} />
          <Route path="access-document" element={<ContactVerification />} />
          <Route path="access-document/verify" element={<VerifyContactOTP />} />
          <Route path="forgot-password" element={<UserForgotPassword />} />
          <Route path="verify-otp" element={<UserOtpVerify />} />
          <Route path="user-notaries" element={<UserNotaries />} />
          <Route path="reset-password" element={<UserResetPassword />} />
          <Route path="register" element={<Register />} />
        </Route>
        <Route path="/" element={<Layout />}>
          <Route path="user" element={<UserPrivateRoutes />}>
            <Route path="dashboard" element={<UserDashboard />} />
            <Route path="notary-document" element={<NotaryDocument />} />
          </Route>
        </Route>
        {/* user end */}

        {/* notary start */}
        <Route path="/" element={<NotaryLayout />}>
          <Route path="notary" element={<NotaryPrivateRoutes />}>
            <Route path="dashboard" element={<NotaryDashboard />} />
            <Route path="add-notary" element={<AddNotary />} />
            <Route path="notaries" element={<Notaries />} />
          </Route>
        </Route>
        {/* notary end */}

        {/* admin start */}
        <Route path="/admin">
          <Route path="" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="verify-otp" element={<OtpVerify />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="register" element={<Register />} />
          <Route path="dashboard" element={<AdminLayout />}>
            <Route path="" element={<AdminDashboard />} />
          </Route>
        </Route>
        {/* admin end */}

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
