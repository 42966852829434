import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useSelector } from "react-redux";
import download from "../../../../assets/images/svg/download.svg";
import moment from "moment";

const NotaryDocument = () => {
  const { userData } = useSelector((state) => state.user);
  const [notary, setNotary] = useState([]);
  // start pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // end pagination

  // get All User Wise Notary
  const getAllUserWiseNotary = async () => {
    await DataService.get(`${API.User.NOTARY_USER_WISE}/${userData?._id}`)
      .then((res) => {
        setNotary(res?.data?.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getAllUserWiseNotary();
  }, []);
  return (
    <Index.Box className="notary-document-main">
      <Index.Box className="notary-common-header">
        <Index.Typography className="header-title">
          Notary Documents List
        </Index.Typography>
      </Index.Box>
      <Index.Box className="table-wrapper card-border common-card">
      <Index.Box className="admin-userlist-table-main page-table-main">
        <Index.TableContainer component={Index.Paper} className='table-container'>
          <Index.Table
            aria-label="simple table"
            className='table'
          >
            <Index.TableHead className='table-head'>
              <Index.TableRow className='table-row'>
                <Index.TableCell component='th' variant='th' className='table-th' width="10%">
                  S. NO.
                </Index.TableCell>
                <Index.TableCell component='th' variant='th' className='table-th' width="10%">
                  Title
                </Index.TableCell>
                <Index.TableCell component='th' variant='th' className='table-th' width="10%">
                  Description
                </Index.TableCell>
                <Index.TableCell component='th' variant='th' className='table-th' width="10%">
                  Notary Lawyer
                </Index.TableCell>
                <Index.TableCell component='th' variant='th' className='table-th' width="10%">
                  Date
                </Index.TableCell>
                <Index.TableCell
                  component='th' variant='th' className='table-th' width="10%"
                >
                  Action
                </Index.TableCell>
              </Index.TableRow>
            </Index.TableHead>
            <Index.TableBody className='table-body'>
              {notary?.length > 0 ? (
                notary
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <Index.TableRow key={row?._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                       <Index.TableCell component='td' variant='td' scope="row" className='table-td'>
                        {index + 1 + page * rowsPerPage}
                      </Index.TableCell>
                       <Index.TableCell component='td' variant='td' scope="row" className='table-td'>
                        {row ? row?.title : "-"}
                      </Index.TableCell>
                       <Index.TableCell component='td' variant='td' scope="row" className='table-td'>
                        {/* {row ? row?.documentDescription : "-"} */}
                        {row?.documentDescription?.slice(0, 8)}
                      {"..."}
                      {row?.documentDescription?.slice(
                        row?.documentDescription?.length - 8,
                        row?.documentDescription?.length
                      )}
                      </Index.TableCell>
                       <Index.TableCell component='td' variant='td' scope="row" className='table-td'>
                        {row ? row?.advocateName : "-"}
                      </Index.TableCell>
                       <Index.TableCell component='td' variant='td' scope="row" className='table-td'>
                        {row?.updatedAt
                          ? moment(row?.updatedAt)?.format("DD-MM-YYYY hh:mm A")
                          : "-"}
                      </Index.TableCell>
                      <Index.TableCell component='td' variant='td' scope="row" className='table-td'>
                        <a
                          href={`https://ipfs.io/ipfs/${row?.ipfsHash}`}
                          target="_blank"
                        >
                          {/* <Index.DownloadIcon className="user-notary-download-icon" /> */}
                          <img src={download} className='admin-icon' alt='Edit'/>
                        </a>
                      </Index.TableCell>
                    </Index.TableRow>
                  ))
              ) : (
                <Index.TableRow>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    scope="row"
                    className="table-td"
                    colSpan={6}
                  >
                    No Record found
                  </Index.TableCell>
                </Index.TableRow>
              )}
            </Index.TableBody>
          </Index.Table>
        </Index.TableContainer>
       
      </Index.Box>
      <Index.Box>
          {notary?.length > 0 && (
            <>
            <Index.TablePagination
              component="div"
              count={notary?.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            </>
          )}
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default NotaryDocument;
