import React from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";
import notaryLogo from "../../../assets/images/notaryLogo.avif";

export default function AdminSidebar(props) {
  return (
    <Index.Box className="admin-main-sidebar">
      <Index.Box className="admin-sidebar-logo">
        {/* <PagesIndex.Svg.CompanyLogo Light /> */}
        <img src={notaryLogo} Light  style={{width:100,height:60}}/>
        <PagesIndex.Button
          onClick={() => {
            props.setOpen(!props.open);
          }}
          className="sidebar-close-btn"
        >
          <PagesIndex.Svg.CloseIcon />
        </PagesIndex.Button>
      </Index.Box>

      <Index.Box className="admin-sidebar-list cus-scrollbar">
        <Index.List className="admin-sidebar-list-inner">
          <Index.ListItem className="admin-sidebar-listitem">
            <Index.Link
              to="#"
              onClick={() => {
                window.innerWidth <= 1023 && props.setOpen(!props.open);
              }}
              className="sidebar-link active"
            >
              <PagesIndex.Svg.DashboardIcon />
              Dashboard
            </Index.Link>
          </Index.ListItem>
          <Index.ListItem className="admin-sidebar-listitem">
            <Index.Link
              to="#"
              onClick={() => {
                window.innerWidth <= 1023 && props.setOpen(!props.open);
              }}
              className="sidebar-link"
            >
              <PagesIndex.Svg.UserAddIcon />
             Admin
            </Index.Link>
          </Index.ListItem>
          {/* <Index.ListItem className="admin-sidebar-listitem">
            <Index.Link
              to="#"
              onClick={() => {
                window.innerWidth <= 1023 && props.setOpen(!props.open);
              }}
              className="sidebar-link"
            >
              <PagesIndex.Svg.UserAddIcon />
              Seller List
            </Index.Link>
          </Index.ListItem> */}
        </Index.List>
      </Index.Box>
    </Index.Box>
  );
}
