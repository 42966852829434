import React from 'react'

export default function ArrowBackIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.7979 10.1723C20.7161 10.159 20.6332 10.1529 20.5504 10.1539H6.44078L6.74844 10.0108C7.04917 9.8685 7.32276 9.67478 7.55695 9.43845L11.5136 5.48176C12.0347 4.98431 12.1223 4.18407 11.7211 3.58569C11.2542 2.94805 10.3588 2.80961 9.72114 3.27651C9.66963 3.31425 9.62066 3.35539 9.57465 3.39967L2.41969 10.5546C1.86053 11.1132 1.86004 12.0192 2.41858 12.5784C2.41893 12.5787 2.41934 12.5791 2.41969 12.5795L9.57465 19.7344C10.1343 20.2925 11.0403 20.2912 11.5984 19.7316C11.6423 19.6876 11.6833 19.6407 11.7211 19.5913C12.1223 18.9929 12.0347 18.1927 11.5136 17.6953L7.5641 13.7314C7.35415 13.5212 7.11276 13.345 6.84861 13.2091L6.41931 13.0159H20.4716C21.2026 13.0431 21.844 12.5324 21.9813 11.8139C22.1078 11.0338 21.578 10.2988 20.7979 10.1723Z" fill="var(--primary)" />
        </svg>
    )
}

