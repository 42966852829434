import React, { useState } from "react";
import PagesIndex from "../../PagesIndex";
import "../../admin/defaultLayout/adminLayout.css";
import Index from "../../../container/Index";
import { Outlet } from "react-router-dom";

export default function NotaryLayout() {
  const [open, setOpen] = useState(true);
  return (
    <>
      {/* <PagesIndex.Header /> */}
      {/* <PagesIndex.Outlet />
      <PagesIndex.Footer /> */}

      <Index.Box className="admin-main">
        <Index.Box
          className={`admin-main-left ${open ? "active" : "deactive"}`}
        >
          <PagesIndex.NotarySidebar open={open} setOpen={setOpen} />
        </Index.Box>
        <Index.Box
          className={`admin-main-right ${open ? "active" : "deactive"}`}
        >
          <PagesIndex.NotaryHeader setOpen={setOpen} open={open} />
          <Index.Box className="admin-content-main">
            <Outlet />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
