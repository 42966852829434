const GET_ALL_USERS = "/notary/all-user";
// const GET_SINGLE_NOTARY = "/notary/single-notary/";
const GET_USER_NOTARIES = "/user/user-notaries/";
const GET_ADVOCATE_WISE = "/notary/advocate-wise/";
const ADD_NOTARY = "/notary/add";
const UPDATE_NOTARY = "/notary/update-notary";
const GET_SINGLE_NOTARY_BASIC = "/notary/single-notary-base/"
const GET_SINGLE_NOTARY_ADVANCED = "/notary/single-notary-adv/"
const GET_SINGLE_USER = "/user/single-user/"

module.exports = {
    GET_ALL_USERS,
    // GET_SINGLE_NOTARY,
    GET_USER_NOTARIES,
    GET_ADVOCATE_WISE,
    ADD_NOTARY,
    UPDATE_NOTARY,
    GET_SINGLE_NOTARY_BASIC,
    GET_SINGLE_NOTARY_ADVANCED,
    GET_SINGLE_USER
}