import { createSlice } from "@reduxjs/toolkit";
import PagesIndex from "../../../../container/PagesIndex";
import { userLogin } from "./UserService";

export const UserSlice = createSlice({
  name: "user",
  initialState: {
    userToken: "",
    notaryToken: "",
    isAuthenticated: false,
    isLoading: false,
    userData: {},
    mode: false,
  },
  reducers: {
    // getUserData: (state, action) => {
    //   state.userData = action.payload;
    // },
    userLogout: (state, action) => {
      state.userToken = "";
    },
    notaryLogout: (state, action) => {
      state.notaryToken = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.fulfilled, (state, action) => {
      console.log(action?.payload, "actiondata");

      state.isAuthenticated = true;
      state.userData = action?.payload;
      if (action?.payload?.role == "User") {
        state.userToken = action?.payload?.token?.token;
      } else if (action?.payload?.role == "Notary") {
        state.notaryToken = action?.payload?.token?.token;
      }
      console.log(state.userToken, "userToken");
      console.log(state.notaryToken, "notaryToken");
    });
  },
});

export const { userLogout, notaryLogout } = UserSlice.actions;

export default UserSlice.reducer;
