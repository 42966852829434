import axios from "axios";

// const API_ENDPOINT = "https://notary-management.appworkdemo.com/api/";
// const API_ENDPOINT = "http://localhost:3044/api/";

const API_ENDPOINT = process.env.REACT_APP_BACKEND_LIVE_URL
// const API_ENDPOINT = process.env.REACT_APP_BACKEND_LOCAL_URL

const DataService = axios.create({
  baseURL: API_ENDPOINT,
});

export { DataService, API_ENDPOINT };
