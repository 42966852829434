import { createTheme, ThemeProvider } from "@mui/material/styles";
import Routers from "./routes/Routes";
// import { CreateContext } from "./utils/GlobalContext";
import "./assets/style/global.css";
import "./assets/style/global.responsive.css";
import "./assets/style/admin.css";
import "./assets/style/admin.responsive.css";
import "./container/admin/auth/auth.css";
import "./container/user/auth/user-auth-contact.css";
import "./container/user/auth/user-otp.css";
import "./container/admin/auth/auth.responsive.css";
import "./container/user/pages/notatyDocument/notaryDocument.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  {
    // console.log = () => {};
    // console.error = () => {};
    // console.debug = () => {};
  }
  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: "Inter, sans-serif",
        fontWeight: "400",
        lineHeight: "normal",
      },
    },
  });

  return (
    // <CreateContext>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <Routers />
      </ThemeProvider>
    // </CreateContext>
  );
}

export default App;
