// import React from "react";

// const NotaryPrivateRoutes = () => {
//   return <div>NotaryPrivateRoutes</div>;
// };

// export default NotaryPrivateRoutes;
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { DataService } from "../config/DataService";

const NotaryPrivateRoutes = () => {
  // Check token expiry
  const isValidToken = (notaryToken) => {
    if (!notaryToken) return false;
    DataService.defaults.headers.common.auth = notaryToken;
    return true;
  };

  const notaryToken = useSelector((store) => store.user.notaryToken);
  return isValidToken(notaryToken) ? <Outlet /> : <Navigate to="/" />;
};

export default NotaryPrivateRoutes;
