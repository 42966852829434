import { contractInstance } from "../contractInstance";
import {toast} from "react-toastify";
export const safeMint = async (signer, address, uri) => {
  try {
    const tx = await contractInstance(signer).safeMint(address, uri);
    const txReceipt = await tx.wait();
    console.log(txReceipt, "Safe Mint response");
    toast.success("Docs Uploaded!");
    return txReceipt;
  } catch (error) {
    console.log(error, "Safe Mint error");
    toast.error("Docs Uploaded Failed or Cancelled!");
    return error;
  }
};

export const tokenURI = async (signer, tokenId) => {
  try {
    const tx = await contractInstance(signer).tokenURI(tokenId);
    const txReceipt = await tx.wait();
    console.log(txReceipt, "Token URI response");
    return txReceipt;
  } catch (error) {
    console.log(error, "Token URI error");
    return error;
  }
};
