import Svg from "../assets/Svg";
import Png from "../assets/Png";
import Jpg from "../assets/Jpg";
import Button from '../component/common/button/Button'
import OtpInput from 'react-otp-input';
import { useNavigate } from "react-router-dom";

export default {
  Svg,
  Png,
  Jpg,
  Button,
  OtpInput,
  useNavigate,
};
