import React from 'react'

export default function TotalIncomeIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="29.686" height="50" viewBox="0 0 29.686 50">
            <g id="__TEMP__SVG__" transform="translate(-14.286 -3.571)">
                <g id="Group_18" data-name="Group 18">
                    <path id="Path_15" data-name="Path 15" d="M14.286,38.5c.529,5.954,5.4,10.179,12.825,10.725v4.343h3.725V49.229c8.107-.639,13.136-5.136,13.136-11.786,0-5.679-3.382-8.964-10.557-10.814l-2.579-.668V12.382c4.007.393,6.711,2.55,7.393,5.771h5.25c-.593-5.714-5.5-9.814-12.643-10.268V3.571H27.111v4.4c-6.925.821-11.679,5.257-11.679,11.271,0,5.193,3.45,8.868,9.5,10.418l2.179.579v14.4c-4.1-.607-6.929-2.857-7.611-6.136H14.286ZM26.4,24.8c-3.725-.939-5.714-2.946-5.714-5.771,0-3.371,2.514-5.861,6.429-6.529V24.986l-.714-.179Zm5.682,6.686c4.6,1.154,6.614,3.068,6.614,6.318,0,3.918-2.95,6.529-7.857,6.925V31.179l1.243.307Z" fill="#00ac78" />
                </g>
            </g>
        </svg>
    )
}

