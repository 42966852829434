import Jpg from '../assets/Jpg'
import Png from '../assets/Png'
import Svg from '../assets/Svg'
import Header from './user/defaultLayout/Header'
import Footer from './user/defaultLayout/Footer'
import { Outlet } from 'react-router-dom'
import AdminHeader from './admin/defaultLayout/AdminHeader'
import AdminSidebar from './admin/defaultLayout/AdminSidebar'
import Button from './common/button/Button'
import { useNavigate } from "react-router-dom";
import Sidebar from './user/defaultLayout/SideBar'
import NotaryHeader from './notary/defaultLayout/NotaryHeader'
import NotarySidebar from './notary/defaultLayout/NotarySideBar'

export default {
      Jpg,
      Png,
      Svg,
      Header,
      Footer,
      Outlet,
      AdminHeader,
      AdminSidebar,
      Button,
      useNavigate,
      Sidebar,
      NotaryHeader,
      NotarySidebar
      
};
