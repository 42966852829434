import React from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { userLogin } from "../../../../redux/features/slices/user/UserService";
import { useNavigate } from "react-router-dom";
import {
  notaryLogout,
  userLogout,
} from "../../../../redux/features/slices/user/UserSlice";

export default function UserLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // password
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const initialValues = {
    email: "",
    password: "",
    rememberMe: false,
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter your valid email")
      .matches(
        /^[a-z][a-z0-9]*(?:\.[a-z]+)*@[a-z]+\.[a-z]{2,}$/,
        "Please enter your valid email"
      )
      .required("Please enter your email address"),
    password: Yup.string()
      .required("Please enter your password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/,
        "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
      ),
  });

  const handleFormSubmit = async (values, { resetForm }) => {
    console.log(values, "values");
    const data = new URLSearchParams();
    data.append("email", values.email);
    data.append("password", values.password);
    dispatch(userLogin(data)).then((res) => {
      if (res?.payload?.status == 200) {
        toast.success(res?.payload?.message);
        resetForm();
        if (res?.payload?.role == "User") {
          navigate("/user/notary-document");
        } else {
          navigate("/notary/notaries");
        }
      } else {
        resetForm();
      }
    });
  };

  return (
    <Index.Box className="admin-main">
      <Index.Box className="auth-main">
        <Index.Box className="auth-left">
          {/* <PagesIndex.Svg.CompanyLogo /> */}
        </Index.Box>
        <Index.Box className="auth-right">
          <Index.Box className="auth-common-box">
            <Index.Box className="auth-header">
              <Index.Box className="auth-title">Login</Index.Box>
              <Index.Box className="auth-subtitle">
                Please enter your credentials to Login
              </Index.Box>
            </Index.Box>
            <Formik
              initialValues={initialValues}
              onSubmit={handleFormSubmit}
              validationSchema={validationSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                setFieldValue,
                handleSubmit,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Index.Box className="auth-body">
                    <Index.Grid container spacing={3}>
                      <Index.Grid item xs={12}>
                        <Index.Box className="form-group">
                          <Index.FormHelperText className="form-lable">
                            Email
                          </Index.FormHelperText>
                          <Index.TextField
                            fullWidth
                            type="text"
                            id="email"
                            name="email"
                            className="form-input"
                            placeholder="Please enter your email"
                            onChange={(e) => {
                              const updatedValue = e.target.value.trim();
                              setFieldValue("email", updatedValue);
                            }}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                        </Index.Box>
                        {errors.email && touched.email && (
                          <p className="error-text">{errors.email}</p>
                        )}
                      </Index.Grid>
                      <Index.Grid item xs={12}>
                        <Index.Box className="form-group">
                          <Index.FormHelperText className="form-lable">
                            Password
                          </Index.FormHelperText>

                          <Index.OutlinedInput
                            fullWidth
                            className="form-input"
                            placeholder="Please enter your password"
                            autocomplete="off"
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={values?.password}
                            onChange={(e) => {
                              setFieldValue("password", e.target.value.trim());
                            }}
                            onBlur={handleBlur}
                            endAdornment={
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                          />
                        </Index.Box>
                        {errors.password && touched.password && (
                          <p className="error-text">{errors.password}</p>
                        )}
                      </Index.Grid>
                      <Index.Grid item xs={6}>
                        <Index.Box className="form-group">
                          {/* <Index.FormControlLabel
                            control={<Index.Checkbox />}
                            label="Remember Me"
                            className="form-checkbox"
                            name="rememberMe"
                            value={values.rememberMe}
                            onChange={() => {
                              setFieldValue("rememberMe", true);
                            }}
                          /> */}
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={6}>
                        <Index.Link
                          to="/forgot-password"
                          className="auth-link forgot-password"
                        >
                          Forgot Password?
                        </Index.Link>
                      </Index.Grid>
                      <Index.Grid item xs={12}>
                        <Index.Box className="main-btn-sec mb-16">
                          <PagesIndex.Button
                            primary
                            // onClick={() => {
                            //   navigate("/admin/dashboard");
                            // }}
                            type="submit"
                          >
                            Login
                          </PagesIndex.Button>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>

                    <Index.Typography
                      className="dont-modal-para"
                      component="p"
                      variant="p"
                    >
                      Don’t have an account?{" "}
                      {/* <span className="auth-redirect-link" onClick={handleOpen}> */}
                      <span
                        className="auth-redirect-link"
                        onClick={() => navigate("/register")}
                      >
                        Sign up
                      </span>
                    </Index.Typography>
                    <Index.Typography
                      className="dont-modal-para"
                      component="p"
                      variant="p"
                    >
                     normal user?{" "}
                      {/* <span className="auth-redirect-link" onClick={handleOpen}> */}
                      <span
                        className="auth-redirect-link"
                        onClick={() => navigate("/otp-login")}
                      >
                        Login with OTP
                      </span>
                    </Index.Typography>
                  </Index.Box>
                </Form>
              )}
            </Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {/* <Register handleOpen={handleOpen} handleClose={handleClose} open={open} /> */}
    </Index.Box>
  );
}
