import React, { useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

export default function UserOtpVerify() {
  const location = useLocation()
  const emailId = location?.state?.email
  console.log(emailId,"emailId")
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleChange = (newValue) => {
    console.log(newValue, "newValue");
    const numericValue = newValue.replace(/\D/g, "");
    setOtp(numericValue);
  };

  const handleVerifyOTP = async () => {
    if (otp == "") {
      setError("Please enter your OTP");
    } else if (otp.length < 4) {
      setError("Otp must be 4-digit number");
    }
    const data = {
      email: emailId,
      otp: Number(otp),
    };
    if (otp?.length == 4) {
      await DataService.post(API.User.OTP_VERIFY, data)
        .then((res) => {
          console.log(res, 24);
          if (res?.data?.status == 200) {
            toast.success(res?.data?.message);
            navigate("/reset-password", {
              state: { row: res?.data?.data?.otp },
            });
          }
          setOtp("");
        })
        .catch((error) => {
          console.log(error, 26);
          if (error) {
            toast.error(error?.response?.data?.message);
            setOtp("");
          }
        });
    }
  };

  return (
    <Index.Box className="admin-main">
      <Index.Box className="auth-main">
        <Index.Box className="auth-left">
          {/* <PagesIndex.Svg.CompanyLogo /> */}
        </Index.Box>
        <Index.Box className="auth-right">
          <Index.Box className="auth-common-box">
            <Index.Box className="auth-header">
              <Index.Box className="auth-title">OTP</Index.Box>
              <Index.Box className="auth-subtitle">Please enter Otp</Index.Box>
            </Index.Box>
            <Index.Box className="auth-body">
              <Index.Grid container spacing={3}>
                <Index.Grid item xs={12}>
                  <Index.Box className="form-group">
                    <PagesIndex.OtpInput
                      value={otp}
                      onChange={handleChange}
                      numInputs={4}
                      inputType="tel"
                      // renderInput={(props) => <input {...props} />}
                      renderInput={(props) => (
                        <input
                          {...props}
                          inputmode="numeric"
                          pattern="[0-9]*"
                        />
                      )}
                      skipDefaultStyles
                      containerStyle="form-input otp-input-box"
                    />
                  </Index.Box>
                  {error && <p className="error-text">{error}</p>}
                </Index.Grid>
                <Index.Grid item xs={12}>
                  <Index.Box className="auth-btn-box">
                    <Index.Link to="/forgot-password" className="auth-link">
                      <PagesIndex.Svg.ArrowBackIcon />
                      Back
                    </Index.Link>
                    <Index.Box className="main-btn-sec">
                    <PagesIndex.Button primary onClick={handleVerifyOTP}>
                      Verify OTP
                    </PagesIndex.Button>
                    </Index.Box>
                    {/* <Index.Link
                      to="/reset-password"
                      className="btn btn-primary"
                    >
                      Verify OTP
                    </Index.Link> */}
                  </Index.Box>
                </Index.Grid>
              </Index.Grid>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
