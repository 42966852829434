import React, { useState } from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'

export default function ResetPassword() {

  const navigate = PagesIndex.useNavigate()

  return (
    <Index.Box className="admin-main">
      <Index.Box className="auth-main">
        <Index.Box className="auth-left">
          {/* <PagesIndex.Svg.CompanyLogo /> */}
        </Index.Box>
        <Index.Box className="auth-right">
          <Index.Box className="auth-common-box">
            <Index.Box className="auth-header">
              <Index.Box className="auth-title">
                Reset Password
              </Index.Box>
              <Index.Box className="auth-subtitle">
                Please enter Password and confirm password
              </Index.Box>
            </Index.Box>
            
            <Index.Box className="auth-body">
              <Index.Grid container spacing={3}>
                <Index.Grid item xs={12}>
                  <Index.Box className="form-group">
                    <Index.FormHelperText className="form-lable">
                      Password
                    </Index.FormHelperText>
                    <Index.TextField
                      fullWidth
                      type="password"
                      id="resetPassword"
                      name="resetPassword"
                      className="form-input"
                      placeholder="Please enter your password"
                    />
                  </Index.Box>
                </Index.Grid>
                <Index.Grid item xs={12}>
                  <Index.Box className="form-group">
                    <Index.FormHelperText className="form-lable">
                      Confirm Password
                    </Index.FormHelperText>
                    <Index.TextField
                      fullWidth
                      type="password"
                      id="resetConfirmPassword"
                      name="resetConfirmPassword"
                      className="form-input"
                      placeholder="Please enter your confirm password"
                    />
                  </Index.Box>
                </Index.Grid>
                <Index.Grid item xs={12}>
                  <Index.Box className="auth-btn-box">
                    <Index.Link to="/admin" className='auth-link'>
                      Cancel
                    </Index.Link>
                    <Index.Box className="main-btn-sec">
                    <PagesIndex.Button
                      primary
                      onClick={() => {
                        navigate("/admin/dashboard")
                      }}
                    >
                      Submit
                    </PagesIndex.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Grid>
              </Index.Grid>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  )
}
