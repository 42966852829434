import React, { useEffect } from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";
import { useDispatch } from "react-redux";
import { userLogout } from "../../../redux/features/slices/user/UserSlice";

export default function Header(props) {
  const navigate = PagesIndex.useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  window.onresize = function resize() {
    if (window.innerWidth <= 1023) {
      props.setOpen(false);
    } else {
      document.body.classList["remove"]("body-overflow");
    }
  };
  useEffect(() => {
    if (window.innerWidth <= 1023) {
      document.body.classList[props.open ? "add" : "remove"]("body-overflow");
    }
  }, [props.open]);

  return (
    <Index.Box className="admin-header">
      <Index.Box className="admin-header-left">
        <PagesIndex.Button
          className="nav-menu-btn"
          onClick={() => {
            props.setOpen(!props.open);
          }}
        >
          <PagesIndex.Svg.HumburgerIcon />
        </PagesIndex.Button>
        {/* <PagesIndex.Svg.CompanyLogo /> */}
      </Index.Box>
      <Index.Box className="admin-header-right">
        <PagesIndex.Button className="icon-box">
          <PagesIndex.Svg.NotificationIcon />
        </PagesIndex.Button>
        <Index.Box className="profile-drop-main">
          <PagesIndex.Button className="profile-drop-btn" onClick={handleClick}>
            <img
              src={PagesIndex.Png.profile}
              className="header-profile"
              alt="User"
            />
          </PagesIndex.Button>
          <Index.Menu
            className="profile-drop-menu"
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <Index.MenuItem
              onClick={() => {
                handleClose();
              }}
              className="profile-drop-menuitem"
            >
              Profile
            </Index.MenuItem>
            <Index.MenuItem
              onClick={() => {
                handleClose();
              }}
              className="profile-drop-menuitem"
            >
              Account Settings
            </Index.MenuItem>
            <Index.MenuItem
              // onClick={() => {
              //   handleClose()
              //   navigate("/admin")
              // }}
              onClick={() => dispatch(userLogout())}
              className="profile-drop-menuitem"
            >
              Sign Out
            </Index.MenuItem>
          </Index.Menu>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
