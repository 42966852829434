import React from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Await, useNavigate } from "react-router-dom";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { toast } from "react-toastify";

export default function UserForgotPassword() {
  const navigate = useNavigate();
  const initialValues = {
    email: "",
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter your valid email")
      .matches(
        /^[a-z][a-z0-9]*(?:\.[a-z]+)*@[a-z]+\.[a-z]{2,}$/,
        "Please enter your valid email"
      )
      .required("Please enter your email address"),
  });
  const handleFormSubmit = async (values, { resetForm }) => {
    console.log(values, "values");
    await DataService.post(API.User.FORGOT_PASSWORD, values)
      .then((res) => {
        if (res?.data?.status == 200) {
          toast.success(res?.data?.message);
          navigate("/verify-otp", { state: { email: values?.email } });
          resetForm();
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        resetForm();
      });
  };
  return (
    <Index.Box className="admin-main">
      <Index.Box className="auth-main">
        <Index.Box className="auth-left">
          {/* <PagesIndex.Svg.CompanyLogo /> */}
        </Index.Box>
        <Index.Box className="auth-right">
          <Index.Box className="auth-common-box">
            <Index.Box className="auth-header">
              <Index.Box className="auth-title">Forgot Password</Index.Box>
              <Index.Box className="auth-subtitle">
                Please check your email to Reset Password
              </Index.Box>
            </Index.Box>
            <Index.Box className="auth-body">
              <Formik
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
                // validationSchema={validationSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  setFieldValue,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Index.Grid container spacing={3}>
                      <Index.Grid item xs={12}>
                        <Index.Box className="form-group">
                          <Index.FormHelperText className="form-lable">
                            Email
                          </Index.FormHelperText>
                          <Index.TextField
                            fullWidth
                            type="text"
                            id="email"
                            name="email"
                            className="form-input"
                            placeholder="Please enter your email"
                            onChange={(e) =>
                              setFieldValue("email", e.target.value.trim())
                            }
                            onBlur={handleBlur}
                            value={values.email}
                          />
                        </Index.Box>
                        {errors.email && touched.email && (
                          <p className="error-text">{errors.email}</p>
                        )}
                      </Index.Grid>
                      <Index.Grid item xs={12}>
                        <Index.Box className="auth-btn-box">
                          <Index.Link to="/" className="auth-link">
                            <PagesIndex.Svg.ArrowBackIcon />
                            Back to login
                          </Index.Link>
                          <Index.Box className="main-btn-sec">
                          <PagesIndex.Button primary type="submit">
                            Send OTP
                          </PagesIndex.Button>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>
                  </Form>
                )}
              </Formik>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
