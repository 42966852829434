import React from 'react'

export default function PlusIcon() {
    return (
        <svg id="__TEMP__SVG__" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 14.771 14.771">
            <g id="Group_29" data-name="Group 29">
                <path id="Path_25" data-name="Path 25" d="M7.386,13.848a6.463,6.463,0,1,1,6.463-6.463,6.463,6.463,0,0,1-6.463,6.463Zm0,.923A7.386,7.386,0,1,0,0,7.386,7.386,7.386,0,0,0,7.386,14.771Z" fill="#fff" />
                <path id="Path_26" data-name="Path 26" d="M9.693,6a.462.462,0,0,1,.462.462v2.77h2.77a.462.462,0,0,1,0,.923h-2.77v2.77a.462.462,0,0,1-.923,0v-2.77H6.462a.462.462,0,0,1,0-.923h2.77V6.462A.462.462,0,0,1,9.693,6Z" transform="translate(-2.307 -2.307)" fill="#fff" />
            </g>
        </svg>
    )
}
