import React from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  loginWithOTP,
  verifyOTP,
} from "../../../../redux/features/slices/user/UserService";
import { useNavigate } from "react-router-dom";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useEffect } from "react";

export default function UserLoginOTP() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [sendingOTP, setSendingOTP] = React.useState(false);
  const [verifyOTPData, setVerifyOTPData] = React.useState({
    phoneNumber: "",
    otp: "",
  });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const initialValues = {
    phoneNumber: "",
    otp: "",
  };
  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .strict(true)
      .matches(/^[1-9][0-9]{9}$/, "Phone number must be 10 digits")
      .required("Please enter phone number"),
    otp: Yup.string()
      .strict(true)
      .matches(/^[0-9]{6}$/, "OTP must be 6 digits")
      .required("Enter OTP"),
  });

  const handleFormSubmit = async (values, { resetForm }) => {
      const data = new URLSearchParams();
      data.append("phone", values.phoneNumber);
      data.append("otp", values.otp);
      // data.append("id", values.otp);
      dispatch(verifyOTP(data)).then((res) => {
        if (res?.payload?.status == 200) {
          console.log(res?.payload?.data, "res?.payload?.data?.phone");
          localStorage.setItem("phone", values.phoneNumber);
          toast.success(res?.payload?.message);
          resetForm();
          navigate("/user-notaries");
        } else {
          toast.error(res?.payload?.message);
          resetForm();
        }
      });
  };

  const sendOTP = async () => {
    const data = new URLSearchParams();
    data.append("phone", verifyOTPData.phoneNumber);
    dispatch(loginWithOTP(data)).then((res) => {
      if (res?.payload?.status == 200) {
        toast.success(res?.payload?.message);
        setSendingOTP(true);
      } else {
        toast.error(res?.payload?.message);
        setSendingOTP(false);
      }
    });
  };

  return (
    <Index.Box className="admin-main">
      <Index.Box className="auth-main">
        <Index.Box className="auth-left">
          {/* <PagesIndex.Svg.CompanyLogo /> */}
        </Index.Box>
        <Index.Box className="auth-right">
          <Index.Box className="auth-common-box">
            <Index.Box className="auth-header">
              <Index.Box className="auth-title">Login with OTP</Index.Box>
            </Index.Box>
            <Formik
              initialValues={initialValues}
              onSubmit={handleFormSubmit}
              validationSchema={validationSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                setFieldValue,
                handleSubmit,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Index.Box className="auth-body">
                    <Index.Grid container spacing={3}>
                      <Index.Grid item xs={12}>
                        <Index.Box className="form-group">
                          <Index.FormHelperText className="form-lable">
                            Phone Number
                          </Index.FormHelperText>
                          <Index.TextField
                            fullWidth
                            type="text"
                            id="phoneNumber"
                            name="phoneNumber"
                            className="form-input"
                            placeholder="Please enter your phoneNumber"
                            maxLength={10}
                            onChange={(e) => {
                              setFieldValue("phoneNumber", e.target.value);
                              setVerifyOTPData({
                                ...verifyOTPData,
                                phoneNumber: e.target.value,
                              });
                            }}
                            onBlur={handleBlur}
                            value={values.phoneNumber}
                          />
                        </Index.Box>
                        {errors.phoneNumber && touched.phoneNumber && (
                          <p className="error-text">{errors.phoneNumber}</p>
                        )}
                      </Index.Grid>
                      {sendingOTP ? (
                        <Index.Grid item xs={12}>
                          <Index.Box className="form-group">
                            <Index.FormHelperText className="form-lable">
                              Enter OTP
                            </Index.FormHelperText>

                            <MuiOtpInput
                              value={values.otp || ""}
                              length={6}
                              name="otp"
                              onChange={(otpValue) => {
                                const numericValue = otpValue.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                if (!isNaN(numericValue)) {
                                  setFieldValue("otp", numericValue);
                                  setVerifyOTPData({
                                    ...verifyOTPData,
                                    otp: numericValue,
                                  });
                                }
                              }}
                              error={Boolean(errors.otp && touched.otp)}
                            />
                          </Index.Box>
                          {errors.otp && touched.otp && (
                            <p className="error-text">{errors.otp}</p>
                          )}
                        </Index.Grid>
                      ) : null}
                      <Index.Grid item xs={12}>
                        {sendingOTP ? (
                          <Index.Typography
                            style={{ textAlign: "right" }}
                            className="dont-modal-para"
                            component="p"
                            variant="p"
                          >
                            Didn't received OTP?{" "}
                            <Index.Button
                              className="auth-redirect-link"
                              disabled={values.phoneNumber?.length !== 10}
                              onClick={() => {
                                if(verifyOTPData?.phoneNumber?.length === 10){
                                  sendOTP();
                                }
                              }}
                              sx={{ textTransform: "none" }}
                            >
                              Resend OTP
                            </Index.Button>
                          </Index.Typography>
                        ) : (
                          <Index.Button
                            onClick={() => {
                              if(verifyOTPData?.phoneNumber?.length === 10){
                                sendOTP();
                              }
                            }}
                            disabled={values.phoneNumber?.length !== 10}
                            className="auth-link forgot-password"
                          >
                            send OTP
                          </Index.Button>
                        )}
                      </Index.Grid>
                      {sendingOTP ? (
                        <Index.Grid item xs={12}>
                          <Index.Box className="main-btn-sec mb-16">
                            <PagesIndex.Button primary type="submit">
                              Login
                            </PagesIndex.Button>
                          </Index.Box>
                        </Index.Grid>
                      ) : (
                        <Index.Grid item xs={12}>
                          <Index.Box className="main-btn-sec mb-16">
                            <PagesIndex.Button
                              primary
                              type="submit"
                              sx={{ width: "100%" }}
                            >
                              Login
                            </PagesIndex.Button>
                          </Index.Box>
                        </Index.Grid>
                      )}
                    </Index.Grid>
                    <Index.Typography
                      className="dont-modal-para"
                      component="p"
                      variant="p"
                    >
                      Notary User?{" "}
                      {/* <span className="auth-redirect-link" onClick={handleOpen}> */}
                      <span
                        className="auth-redirect-link"
                        onClick={() => navigate(-1)}
                      >
                        Sign up
                      </span>
                    </Index.Typography>
                  </Index.Box>
                </Form>
              )}
            </Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {/* <Register handleOpen={handleOpen} handleClose={handleClose} open={open} /> */}
    </Index.Box>
  );
}
