import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
import Button from "../../common/button/Button";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import {
  addNotary,
  getAllAdvocateNotaries,
  getAllUsers,
  updateNotary,
} from "../../../ApiHelpers/notaryCalls";
import { safeMint } from "../../../web3/callHelpers/calls";
// import { useMyContext } from "../../../utils/GlobalContext";
import { Triangle } from "react-loader-spinner";
import { toast } from "react-toastify";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import Select from 'react-select';
import getEventsFromTransactionHash from "../../../web3/getEvents";
import { Autocomplete, FormHelperText } from "@mui/material";

export default function AddressForm() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  // const {
  //   connectWallet,
  //   connected,
  //   loading,
  //   walletAddress,
  //   balance,
  //   signer,
  //   setLoading,
  // } = useMyContext();

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const supportedFileType = "application/pdf";
  const [loading, setLoading] = React.useState(false);
  const [signer, setSigner] = React.useState(null);
  const [provider, setProvider] = React.useState(null);
  const [documentData, setDocumentData] = React.useState({
    title: "",
    description: "",
    userId: "",
    file: null,
    consent: false,
  });
  const [open, setOpen] = React.useState(false);
  const [currentDoc, setCurrentDoc] = React.useState(null);
  const [selectedUser, setSelectedUser] = React.useState("");
  const [users, setUsers] = React.useState([]);

  const handleOpen = () => {
    console.log(
      selectedUser,
      documentData.description,
      documentData.title, "Response")
    if (address?.length === 0 || !isConnected || address === undefined) {
      toast.error("Connect Wallet first!");
      return;
    }
    if (
      selectedUser?.length === 0 ||
      documentData.description?.length === 0 ||
      documentData.title?.length === 0 ||
      documentData.file === null
    ) {
      toast.error("Insufficient Details!");
      return;
    }
    if (documentData.file?.type !== "application/pdf") {
      toast.error("Only PDF Files are allowed");
      return;
    }
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  // Setter functions
  const setDocumentTitle = (title) => {
    setDocumentData((prevData) => ({ ...prevData, title }));
  };

  const setDocumentDescription = (description) => {
    setDocumentData((prevData) => ({ ...prevData, description }));
  };

  const setUserId = (userId) => {
    setDocumentData((prevData) => ({ ...prevData, userId }));
  };

  const setFile = (file) => {
    setDocumentData((prevData) => ({ ...prevData, file }));
  };

  const setConsent = (consent) => {
    setDocumentData((prevData) => ({ ...prevData, consent }));
  };

  const handleFileUpload = (event) => {
    console.log(event.target.files[0]);
    setFile(event.target.files[0]);
    setCurrentDoc(event.target.files[0]?.name);
  };

  const _addNotary = async () => {
    console.log(documentData, "-> docs");
    setLoading(true);
    try {
      handleClose();
      const _id = localStorage.getItem("_id");
      if (_id?.length && documentData?.userId?.length) {
        const addResponse = await addNotary(
          _id,
          documentData.userId,
          documentData.description,
          documentData.title,
          address?.length && address,
          documentData.file
        );

        if (addResponse.status === 200) {
          toast.success("Data uploaded to ipfs, waiting for confirmation...");
          console.log(addResponse, "Add Response");
          try {
            const res = await safeMint(
              signer,
              address,
              addResponse?.data?.metadataIPFSHash
            );
            console.log(res, "safeMint Response", addResponse);
            const mintedTokenId = await getEventsFromTransactionHash(provider, res?.transactionHash);
            console.log(mintedTokenId, "Minted Token Id");
            if (mintedTokenId != null) {
              const _update = await updateNotary(addResponse?.data?.data, mintedTokenId);
              console.log(_update, "Update Response");
            } else {
              toast.error("Error while updating tokenId");
            }
            setDocumentData({
              file: null,
              title: "",
              description: "",
              userId: "",
              consent: false,
            });
            setSelectedUser("");
            setLoading(false);
          } catch (error) {
            console.log(error, "safeMint error");
          }
        } else {
          toast.error(addResponse.message);
        }
      } else {
        toast.error("No Advocator id found");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const _getAllUsers = async () => {
    const allUsers = await getAllUsers();
    // console.log(allUsers?.data?.data, "All Users");
    let _newArray = ["Maulik"];
    // let data = await Promise.all(allUsers?.data?.data?.map((user) => {
    //   let temp = user;
    //   temp.label = user?.firstName + " " + user?.lastName
    //   _newArray.push(temp)
    //   return temp;
    // }))
    console.log(_newArray, "New Array");
    setUsers(_newArray);
  };


  const setSignerObj = async () => {
    if (isConnected) {
      const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const provider = signer.provider;
      setProvider(provider);
      setSigner(signer);
    }
  };
  React.useEffect(() => {
    setSignerObj();
    _getAllUsers();
  }, [isConnected, walletProvider]);
  console.log("Is Connected", isConnected, "User Address", address);

  return (
    <Box className="add-notary-wrapper">
      {/* <React.Fragment> */}
      {loading ? (
        <Box sx={{ textAlign: "center" }}>
          <Triangle
            visible={true}
            height="80"
            width="80"
            color="#1976d2"
            ariaLabel="triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </Box>
      ) : (
        <Grid container spacing={3} className="create-notary-form">
          <Grid item xs={12}>
            <Box className="admin-input-box admin-add-user-input">
              <FormHelperText className='admin-form-lable'>Document Type</FormHelperText>
              <Box className="admin-form-group">
                <TextField
                  required
                  id="doc_type"
                  name="doc_type"
                  fullWidth
                  value={documentData.title}
                  autoComplete="given-name"
                  // variant="standard"
                  onChange={(e) => setDocumentTitle(e.target.value)}
                  className="admin-form-control"
                  placeholder="Document type"
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} >
            <Box className="admin-input-box admin-add-user-input">
              <FormHelperText className='admin-form-lable'>Document Description</FormHelperText>
              <Box className="admin-form-group">
                {/* <TextField
                  required
                  id="doc_desc"
                  name="doc_desc"
                  fullWidth
                  value={documentData.description}
                  autoComplete="family-name"
                  onChange={(e) => setDocumentDescription(e.target.value)}
                  className="admin-form-control"
                  placeholder="Document Description"
                /> */}
                <textarea
                  required
                  id="doc_desc"
                  name="doc_desc"
                  fullWidth
                  value={documentData.description}
                  autoComplete="family-name"
                  onChange={(e) => setDocumentDescription(e.target.value)}
                  className="cus-textarea"
                  aria-label="minimum height"
                  minRows={3}
                  placeholder="Document Description" />
              </Box>
            </Box>
            {/* <TextField
              required
              id="doc_desc"
              name="doc_desc"
              label="Document Description"
              fullWidth
              value={documentData.description}
              autoComplete="family-name"
              variant="standard"
              onChange={(e) => setDocumentDescription(e.target.value)}
            /> */}
          </Grid>
          <Grid item xs={12} >
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              fullWidth
              onChange={handleFileUpload}
              className="upload-btn"
            >
              Upload Document
              <VisuallyHiddenInput type="file" />
            </Button>
            <Box sx={{ mt: 0.5, ml: 0.5 }}>
              <Box>{documentData?.file?.name}</Box>
              {documentData?.file?.type !== supportedFileType ? (
                <Box sx={{ color: "red", fontSize: "14px" }}>please upload PDF file only*</Box>
              ) : null}
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            // <FormControl variant="standard" sx={{}} fullWidth>
            //   <InputLabel id="demo-simple-select-standard-label">
            //     User
            //   </InputLabel>
            //   <Select
            //     labelId="demo-simple-select-standard-label"
            //     id="demo-simple-select-standard"
            //     value={selectedUser}
            //     label="Users"
            //   >
            //     <MenuItem value="">
            //       <em>None</em>
            //     </MenuItem>
            //     {users?.length &&
            //       users?.map((user) => {
            //         return (
            //           <MenuItem
            //             key={user?._id}
            //             onClick={() => {
            //               handleChange(user?._id);
            //             }}
            //             value={user?._id}
            //           >
            //             {user?.firstName} {user?.lastName}
            //           </MenuItem>
            //         );
            //       })}
            //   </Select>
            // </FormControl>
          </Grid> */}

          <Grid item xs={12} >
            {/* <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? 'blue' : 'grey',
                  backgroundColor: '#fff',
                }),
              }}
              options={users}
              onChange={(opt) => { handleChange(opt?._id); console.log("Selected Item", opt) }}
            /> */}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={users}
              className='cus-autocomplete'
              value={selectedUser}
              onInputChange={(event, newInputValue) => {
                setSelectedUser(newInputValue);
              }}
              onChange={(event, newValue) => {
                setSelectedUser(newValue);
              }}
              renderInput={(params) => <TextField {...params} className="autocom-input" placeholder="Select..." />}
            />
          </Grid>

          {/* Modal starts */}
          <Modal
            disableBackdropClick
            open={open}
            oa-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <form
                onSubmit={() => { }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  gap: "16px",
                  padding: "16px",
                }}
              >
                <Typography variant="h6">Confirm Upload</Typography>
                <Typography color="textSecondary">
                  once you'll upload the document you'll not be able to change
                  it
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        name="saveAddress"
                        onClick={(e) => {
                          setConsent(e.target.checked);
                        }}
                      />
                    }
                    label="Mark to confirm the Documents"
                  />
                </div>
                <div style={{ alignSelf: "end", display: "flex", gap: "16px" }}>
                  <Button
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                  {!documentData.consent ? (
                    <Button
                      disabled={true}
                      variant="contained"
                      onClick={() => {
                        _addNotary();
                      }}
                    >
                      Upload
                    </Button>
                  ) : (
                    <Button
                      disabled={false}
                      variant="contained"
                      onClick={() => {
                        _addNotary();
                      }}
                    >
                      Upload
                    </Button>
                  )}
                </div>
              </form>
            </Box>
          </Modal>
          {/* Modal Ends */}
          {/* <Grid
            item
            xs={12}
            sm={12}
            sx={{ display: "flex", justifyContent: "end", }}
            className="pt-0"
          >

            <Box className="main-btn-sec">
              <Button
                primary
                variant="contained"
                // className="main-btn"
                onClick={() => {
                  handleOpen();
                }}
              >
                Next
              </Button>
            </Box>
          </Grid> */}
        </Grid>
      )
      }
      {/* </React.Fragment> */}
    </Box >
  );
}
const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
  { label: '12 Angry Men', year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: 'Pulp Fiction', year: 1994 },
  {
    label: 'The Lord of the Rings: The Return of the King',
    year: 2003,
  },
  { label: 'The Good, the Bad and the Ugly', year: 1966 },
  { label: 'Fight Club', year: 1999 },
  {
    label: 'The Lord of the Rings: The Fellowship of the Ring',
    year: 2001,
  },
  {
    label: 'Star Wars: Episode V - The Empire Strikes Back',
    year: 1980,
  },
  { label: 'Forrest Gump', year: 1994 },
  { label: 'Inception', year: 2010 },
  {
    label: 'The Lord of the Rings: The Two Towers',
    year: 2002,
  },
  { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { label: 'Goodfellas', year: 1990 },
  { label: 'The Matrix', year: 1999 },
  { label: 'Seven Samurai', year: 1954 },
  {
    label: 'Star Wars: Episode IV - A New Hope',
    year: 1977,
  },
  { label: 'City of God', year: 2002 },
  { label: 'Se7en', year: 1995 },
  { label: 'The Silence of the Lambs', year: 1991 },
  { label: "It's a Wonderful Life", year: 1946 },
  { label: 'Life Is Beautiful', year: 1997 },
  { label: 'The Usual Suspects', year: 1995 },
  { label: 'Léon: The Professional', year: 1994 },
  { label: 'Spirited Away', year: 2001 },
  { label: 'Saving Private Ryan', year: 1998 },
  { label: 'Once Upon a Time in the West', year: 1968 },
  { label: 'American History X', year: 1998 },
  { label: 'Interstellar', year: 2014 },
  { label: 'Casablanca', year: 1942 },
  { label: 'City Lights', year: 1931 },
  { label: 'Psycho', year: 1960 },
  { label: 'The Green Mile', year: 1999 },
  { label: 'The Intouchables', year: 2011 },
  { label: 'Modern Times', year: 1936 },
  { label: 'Raiders of the Lost Ark', year: 1981 },
  { label: 'Rear Window', year: 1954 },
  { label: 'The Pianist', year: 2002 },
  { label: 'The Departed', year: 2006 },
  { label: 'Terminator 2: Judgment Day', year: 1991 },
  { label: 'Back to the Future', year: 1985 },
  { label: 'Whiplash', year: 2014 },
  { label: 'Gladiator', year: 2000 },
  { label: 'Memento', year: 2000 },
  { label: 'The Prestige', year: 2006 },
  { label: 'The Lion King', year: 1994 },
  { label: 'Apocalypse Now', year: 1979 },
  { label: 'Alien', year: 1979 },
  { label: 'Sunset Boulevard', year: 1950 },
  {
    label: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
    year: 1964,
  },
  { label: 'The Great Dictator', year: 1940 },
  { label: 'Cinema Paradiso', year: 1988 },
  { label: 'The Lives of Others', year: 2006 },
  { label: 'Grave of the Fireflies', year: 1988 },
  { label: 'Paths of Glory', year: 1957 },
  { label: 'Django Unchained', year: 2012 },
  { label: 'The Shining', year: 1980 },
  { label: 'WALL·E', year: 2008 },
  { label: 'American Beauty', year: 1999 },
  { label: 'The Dark Knight Rises', year: 2012 },
  { label: 'Princess Mononoke', year: 1997 },
  { label: 'Aliens', year: 1986 },
  { label: 'Oldboy', year: 2003 },
  { label: 'Once Upon a Time in America', year: 1984 },
  { label: 'Witness for the Prosecution', year: 1957 },
  { label: 'Das Boot', year: 1981 },
  { label: 'Citizen Kane', year: 1941 },
  { label: 'North by Northwest', year: 1959 },
  { label: 'Vertigo', year: 1958 },
  {
    label: 'Star Wars: Episode VI - Return of the Jedi',
    year: 1983,
  },
  { label: 'Reservoir Dogs', year: 1992 },
  { label: 'Braveheart', year: 1995 },
  { label: 'M', year: 1931 },
  { label: 'Requiem for a Dream', year: 2000 },
  { label: 'Amélie', year: 2001 },
  { label: 'A Clockwork Orange', year: 1971 },
  { label: 'Like Stars on Earth', year: 2007 },
  { label: 'Taxi Driver', year: 1976 },
  { label: 'Lawrence of Arabia', year: 1962 },
  { label: 'Double Indemnity', year: 1944 },
  {
    label: 'Eternal Sunshine of the Spotless Mind',
    year: 2004,
  },
  { label: 'Amadeus', year: 1984 },
  { label: 'To Kill a Mockingbird', year: 1962 },
  { label: 'Toy Story 3', year: 2010 },
  { label: 'Logan', year: 2017 },
  { label: 'Full Metal Jacket', year: 1987 },
  { label: 'Dangal', year: 2016 },
  { label: 'The Sting', year: 1973 },
  { label: '2001: A Space Odyssey', year: 1968 },
  { label: "Singin' in the Rain", year: 1952 },
  { label: 'Toy Story', year: 1995 },
  { label: 'Bicycle Thieves', year: 1948 },
  { label: 'The Kid', year: 1921 },
  { label: 'Inglourious Basterds', year: 2009 },
  { label: 'Snatch', year: 2000 },
  { label: '3 Idiots', year: 2009 },
  { label: 'Monty Python and the Holy Grail', year: 1975 },
];
