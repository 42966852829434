const { ethers } = require('ethers');
const abi = require('./abis/nft.json')
async function getEventsFromTransactionHash(_provider, transactionHash) {
    try {
        // Get transaction receipt
        const receipt = await _provider.getTransactionReceipt(transactionHash);
        // console.log('Receipt:', receipt.logs[0].topics);
        if (!receipt) {
            console.error('Transaction receipt not found');
            return [];
        }
        
        // Parse events using the contract ABI
        const contractAbi = abi;
        const contractInterface = new ethers.utils.Interface(contractAbi);
        const events = contractInterface.parseLog(receipt.logs[0]);

        console.log('Events:', events.args.tokenId.toString());
        return events.args.tokenId.toString();
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
}

export default getEventsFromTransactionHash