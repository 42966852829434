import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { DataService } from "../config/DataService";

const UserPrivateRoutes = () => {
  // Check token expiry
  const isValidToken = (userToken) => {
    if (!userToken) return false;
    DataService.defaults.headers.common.auth = userToken;
    return true;
  };

  const userToken = useSelector((store) => store.user.userToken);

  return isValidToken(userToken) ? <Outlet /> : <Navigate to="/" />;
};

export default UserPrivateRoutes;
