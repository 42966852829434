import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Index from '../../Index'
import PagesIndex from '../../PagesIndex'
import './adminLayout.css'
import './adminLayout.responsive.css'

export default function AdminLayout() {

  const [open, setOpen] = useState(true);

  return (
    <Index.Box className="admin-main">
      <Index.Box className={`admin-main-left ${open ? "active" : "deactive"}`}>
        <PagesIndex.AdminSidebar open={open} setOpen={setOpen} />
      </Index.Box>
      <Index.Box className={`admin-main-right ${open ? "active" : "deactive"}`}>
        <PagesIndex.AdminHeader setOpen={setOpen} open={open} />
        <Index.Box className="admin-content-main">
          <Outlet />
        </Index.Box>
      </Index.Box>
    </Index.Box>
  )
}
