import React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  addNotary,
  getAllAdvocateNotaries,
  getAllUsers,
} from "../../../ApiHelpers/notaryCalls";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ClearIcon from "@mui/icons-material/Clear";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import moment from "moment";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import download from "../../../assets/images/svg/download.svg";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../common/button/Button";
import PlusIcon from "../../../assets/images/svg/PlusIcon";
import CloseIcon from "../../../assets/images/svg/CloseIcon";
import { toast } from "react-toastify";
import { Triangle } from "react-loader-spinner";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Popover from "@mui/material/Popover";
import { RWebShare } from "react-web-share";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import Editor from "ckeditor5-custom-build/build/ckeditor";

const Index = () => {
  const supportedFileType = "application/pdf";
  const navigate = useNavigate();

  const [notaries, setNotaries] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [addNotaryOpen, setAddNotaryOpen] = React.useState(false);
  const addNotaryHandleClose = () => setAddNotaryOpen(false);
  const addNotarHandleOpen = () => setAddNotaryOpen(true);

  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const confirmHandleOpen = () => setConfirmOpen(true);
  const confirmHandleClose = () => setConfirmOpen(false);

  const [desc, setDesc] = React.useState(false);
  const [descData, setDescData] = React.useState("");
  const handleDescOpen = (_desc) => {
    setDescData(_desc);
    setDesc(true);
  };
  const handleDescClose = () => setDesc(false);

  const [documentData, setDocumentData] = React.useState({
    title: "",
    description: "",
    userId: "",
    file: null,
    consent: false,
  });
  const [open, setOpen] = React.useState(false);
  const [openAddUserDialog, setOpenAddUserDialog] = React.useState(false);
  const [manualUsers, setManualUsers] = React.useState([]);
  const [isCopied, setIsCopied] = React.useState(null);

  const [formData, setFormData] = React.useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
  });

  const [errors, setErrors] = React.useState({
    firstName: false,
    lastName: false,
    phoneNumber: false,
  });

  // hover to pop-up
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const _open = Boolean(anchorEl);

  const handleInputChange = (field) => (event) => {
    const inputValue = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [field]: inputValue,
    }));

    // Display error only when the field is touched and the input is invalid
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: inputValue.length > 0 && inputValue.length < 3,
    }));
    // to check phone number length
    if (field === "phoneNumber") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: inputValue.length > 0 && inputValue.length !== 10,
      }));
    }
  };

  const handleAddUsersOpen = () => {
    console.log(manualUsers?.length, "Manual Users");
    if (manualUsers?.length == 10) {
      toast.error("Maximum 10 users can be added");
      return;
    } else {
      setOpenAddUserDialog(true);
    }
  };

  const handleAddUsersClose = () => {
    setOpenAddUserDialog(false);
  };

  const handleAddUsers = () => {
    if (
      !errors.firstName &&
      !errors.lastName &&
      !errors.phoneNumber &&
      formData.firstName.length > 0 &&
      formData.lastName.length > 0 &&
      formData.phoneNumber.length > 0
    ) {
      console.log(formData.firstName + " " + formData.lastName, "New Value");
      setManualUsers((prevUsers) => [
        ...prevUsers,
        {
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: formData.phoneNumber,
        },
      ]);
      setFormData({});
      handleAddUsersClose();
    } else {
      toast.error("Fill all details");
    }
  };
  const handleRemoveUser = (index) => {
    setManualUsers((prevUsers) => {
      const updatedUsers = [...prevUsers];
      updatedUsers.splice(index, 1);
      return updatedUsers;
    });
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleOpen = () => {
    if (
      documentData.description?.length === 0 ||
      documentData.title?.length === 0 ||
      documentData.file === null
    ) {
      console.log(documentData.userId, "ioio");
      toast.error("Insufficient Details!");
      return;
    }
    if (documentData.file?.type !== "application/pdf") {
      toast.error("Only PDF Files are allowed");
      return;
    }
    confirmHandleOpen();
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Setter functions
  const setDocumentTitle = (title) => {
    setDocumentData((prevData) => ({ ...prevData, title }));
  };

  const setDocumentDescription = (description) => {
    setDocumentData((prevData) => ({ ...prevData, description }));
  };

  const setUserId = (userId) => {
    setDocumentData((prevData) => ({ ...prevData, userId }));
  };

  const setFile = (file) => {
    setDocumentData((prevData) => ({ ...prevData, file }));
  };

  const setConsent = (consent) => {
    setDocumentData((prevData) => ({ ...prevData, consent }));
  };

  const resetDocumentData = () => {
    setDocumentData({
      title: "",
      description: "",
      userId: "",
      file: null,
      consent: false,
    })
    setManualUsers([]);
  }

  const handleFileUpload = (event) => {
    console.log(event.target.files[0]);
    setFile(event.target.files[0]);
  };

  const loadAdvocateNotaries = async () => {
    const _id = localStorage.getItem("_id");
    if (_id == "undefined") {
      navigate(-1)
    }
    const allAdvocateNot = await getAllAdvocateNotaries(_id);
    setNotaries(allAdvocateNot?.data?.data);
    console.log(allAdvocateNot?.data?.data, "allAdvocateNotallAdvocateNot");
  };

  const _addNotary = async () => {
    setConfirmOpen(false);
    setAddNotaryOpen(false);
    setLoading(true);
    try {
      const _id = localStorage.getItem("_id");
      if (_id?.length) {
        setAddNotaryOpen(false);
        setConfirmOpen(false);
        toast.info("Data is being uploaded...");
        const addResponse = await addNotary(
          _id,
          documentData.title,
          documentData.file,
          manualUsers,
          documentData.description
        );
        if (addResponse.status === 200) {
          toast.success("Data uploaded to ipfs");
          console.log(addResponse, "Add Response");
          await loadAdvocateNotaries();
          setLoading(false);
          resetDocumentData();
        } else {
          toast.error(addResponse.message);
          await loadAdvocateNotaries();
          setLoading(false);
          resetDocumentData();
        }
      } else {
        resetDocumentData();
        toast.error("No Advocator id found");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      resetDocumentData();
    }
    setLoading(false);
  };

  // const _getAllUsers = async (_key) => {
  //   console.log(_key, "Key");
  //   if (_key?.length >= 3) {
  //     setSelectText(_key);
  //     const allUsers = await getAllUsers(selectText);
  //     console.log(allUsers?.data?.data, "All Users");
  //     // setUsers
  //     let _newArray = [];
  //     let data = await Promise.all(
  //       allUsers?.data?.data?.map((user) => {
  //         let temp = user;
  //         temp = {
  //           firstName: user?.firstName,
  //           lastName: user?.lastName,
  //           phone: "9948566789",
  //         };
  //         _newArray.push(temp);
  //         return temp;
  //       })
  //     );
  //     console.log(_newArray, "New Array");
  //     setUsers(_newArray);
  //   }
  //   if (_key?.length < 3) {
  //     setUsers([]);
  //   }
  // };

  React.useEffect(() => {
    loadAdvocateNotaries();
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      setIsCopied(-1)
    }, 3000)
  }, [isCopied])

  const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
  };

  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  );


  return (
    <>
      {loading ? (
        <div className="loader-main">
          <div className="loader-sec">
            <Triangle
              visible={true}
              height="80"
              width="80"
              color="#1976d2"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </div>
      ) : (
        <div>
          <Dialog
            open={openAddUserDialog}
            // onClose={openAddUserDialog}
            PaperProps={{
              component: "form",
              onSubmit: (event) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());
                const email = formJson.email;
                console.log(email);
                // handleClose();
                handleAddUsersClose();
              },
            }}
          >
            <DialogTitle>Add User</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    required
                    id="outlined-required"
                    label="First Name"
                    placeholder="Enter your first name"
                    value={formData.firstName}
                    onChange={handleInputChange("firstName")}
                    helperText={
                      errors.firstName &&
                      "Minimum 3 characters required for first name."
                    }
                    error={errors.firstName}
                  />

                  <TextField
                    required
                    id="outlined-required"
                    label="Last Name"
                    placeholder="Enter your last name"
                    value={formData.lastName}
                    onChange={handleInputChange("lastName")}
                    helperText={
                      errors.lastName &&
                      "Minimum 3 characters required for last name."
                    }
                    error={errors.lastName}
                  />

                  <TextField
                    required
                    id="outlined-required"
                    label="Phone Number"
                    type="number"
                    placeholder="Enter your 10-digit phone number"
                    value={formData.phoneNumber}
                    onChange={handleInputChange("phoneNumber")}
                    helperText={
                      errors.phoneNumber &&
                      "Invalid phone number. Please enter a valid 10-digit phone number."
                    }
                    error={errors.phoneNumber}
                  />
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAddUsersClose}>Cancel</Button>
              <Button onClick={handleAddUsers}>Add</Button>
            </DialogActions>
          </Dialog>
          <Box className="admin-user-list-flex admin-page-title-main">
            <Typography
              className="admin-page-title"
              component="h2"
              variant="h2"
            >
              My Notaries
            </Typography>
            <Box className="main-btn-sec">
              <Button primary onClick={() => { addNotarHandleOpen() }}>
                <PlusIcon /> Add Notary
              </Button>
            </Box>
          </Box>
          <Box className="table-wrapper card-border common-card">
            <Box className="admin-userlist-table-main page-table-main">
              <TableContainer component={Paper} className="table-container">
                <Table aria-label="simple table" className="table">
                  <TableHead className="table-head">
                    <TableRow className="table-row">
                      <TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="10%"
                      >
                        Title
                      </TableCell>
                      <TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="10%"
                      >
                        Created On
                      </TableCell>
                      {/* <TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="10%"
                      >
                        User
                      </TableCell> */}
                      <TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="10%"
                      >
                        Description
                      </TableCell>
                      <TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="10%"
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {notaries
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((_, index) => (
                      <TableBody className="table-body" key={index}>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                          >
                            <Box className="admin-table-data-flex">
                              <Typography className="admin-table-data-text">
                                {" "}
                                {_?.title}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                          >
                            <Box className="admin-table-data-flex">
                              <Typography className="admin-table-data-text">
                                {" "}
                                {moment(_?.createdAt)?.format(
                                  "DD-MM-YYYY hh:mm A"
                                )}
                              </Typography>
                            </Box>
                          </TableCell>
                          {/* <TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                          >
                            {_?.candidateFirstName} {_?.candidateLastName}
                          </TableCell> */}
                          <TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              handleDescOpen(_?.description);
                            }}
                            aria-owns={_open ? "mouse-over-popover" : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                          >
                            <Box >
                              {" "}
                              {_?.description?.slice(0, 50)}
                              {/* {"..."}
                            {_?.description?.slice(
                              _?.description?.length - 3,
                              _?.description?.length
                            )} */}
                              <Popover
                                id="mouse-over-popover"
                                sx={{
                                  pointerEvents: "none",
                                  width: "120px",
                                  height: "80px",
                                }}
                                open={_open}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                              >
                                <Typography sx={{ p: 1, fontSize: "10px" }}>
                                  Click to expand.
                                </Typography>
                              </Popover>

                            </Box>
                          </TableCell>
                          <TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                          ><Box sx={{ display: "flex", gap: "10px", }}>
                              <Box className="admin-table-data-btn-flex" sx={{ marginLeft: "-4%" }}>
                                <Link
                                  to={`https://ipfs.io/ipfs/${_?.ipfsHash}`}
                                  target="_blank"
                                >
                                  <Button
                                    className="admin-table-data-btn"
                                    onClick={() => { }}
                                  >
                                    <img
                                      src={download}
                                      className="admin-icon"
                                      alt="Edit"
                                    ></img>
                                  </Button>
                                </Link>
                              </Box>
                              <RWebShare
                                data={{
                                  text: _?.document,
                                  url: `${process.env.REACT_APP_GENERIC_SHARE_URL}?id=${_?._id}`,
                                  title: _?.title,
                                }}
                                onClick={() =>
                                  console.log("shared successfully!")
                                }
                              >
                                <ShareIcon />
                              </RWebShare>
                              <Box>
                                {index === isCopied ? <DoneIcon sx={{ cursor: "pointer", color: "green" }} >
                                </DoneIcon> : <ContentCopyIcon sx={{ cursor: "pointer" }} onClick={() => {
                                  setIsCopied(index)
                                  const data = (process.env.REACT_APP_GENERIC_SHARE_URL).toString() + '?id=' + _?._id
                                  navigator.clipboard.writeText(`${process.env.REACT_APP_GENERIC_SHARE_URL}?id=${_?._id}`)
                                }} >
                                </ContentCopyIcon>}
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                </Table>
              </TableContainer>
            </Box>
            <Box className="admin-pagination-main">
              <TablePagination
                component="div"
                count={notaries?.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            {/* Add Notary Modal start*/}
            <Dialog
              open={addNotaryOpen}
              onClose={addNotaryHandleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="import-steps-modal add-notary-modal"
            >
              <DialogTitle id="alert-dialog-title" className="modal-title">
                {"Add Notary"}
                <Box className="close-btn-sec" onClick={() => { addNotaryHandleClose() }}>
                  <CloseIcon className="modal-close-ic" />
                </Box>
              </DialogTitle>
              <DialogContent className="modal-body">
                <Box id="alert-dialog-description" className="modal-content">
                  {/* <AddressForm /> */}

                  <Box className="add-notary-wrapper">
                    {/* <React.Fragment> */}

                    <Grid container spacing={3} className="create-notary-form">
                      <Grid item xs={12}>
                        <Box className="admin-input-box admin-add-user-input">
                          <FormHelperText className="admin-form-lable">
                            Document Type
                          </FormHelperText>
                          <Box className="admin-form-group">
                            <TextField
                              required
                              id="doc_type"
                              name="doc_type"
                              fullWidth
                              value={documentData.title}
                              autoComplete="off"
                              onChange={(e) => setDocumentTitle(e.target.value)}
                              className="admin-form-control"
                              placeholder="Document type"
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box className="admin-input-box admin-add-user-input">
                          <FormHelperText className="admin-form-lable">
                            Document Description
                          </FormHelperText>
                          <Box className="admin-form-group">
                            {/* <textarea
                              required
                              id="doc_desc"
                              name="doc_desc"
                              fullWidth
                              value={documentData.description}
                              autoComplete="family-name"
                              onChange={(e) =>
                                setDocumentDescription(e.target.value)
                              }
                              className="cus-textarea"
                              aria-label="minimum height"
                              minRows={3}
                              placeholder="Document Description"
                            /> */}
                            {/* <CKEditor/> */}
                            {/* <Textarea
                              fullWidth
                              color="neutral"
                              disabled={false}
                              minRows={2}
                              placeholder="Description"
                              size="md"
                              value={documentData.description}
                              onChange={(e) => {
                                setDocumentDescription(e.target.value);
                              }}
                            /> */}
                            <textarea
                              required
                              id="doc_desc"
                              name="doc_desc"
                              fullWidth
                              value={documentData.description}
                              autoComplete="family-name"
                              onChange={(e) => setDocumentDescription(e.target.value)}
                              className="cus-textarea"
                              aria-label="minimum height"
                              minRows={3}
                              placeholder="Document Description" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                          fullWidth
                          onChange={handleFileUpload}
                          className="upload-btn"
                        >
                          Upload Document
                          <VisuallyHiddenInput type="file" />
                        </Button>
                        <Box sx={{ mt: 0.5, ml: 0.5 }}>
                          <Box>{documentData?.file?.name}</Box>
                          {documentData?.file?.type !== supportedFileType ? (
                            <Box sx={{ color: "red", fontSize: "14px" }}>
                              please upload PDF file only*
                            </Box>
                          ) : null}
                        </Box>
                      </Grid>
                      {/* <Grid item xs={12}>
                        <Autocomplete
                          id="country-select-demo"
                          sx={{ width: 300 }}
                          options={users?.map((_) => {
                            return _?.firstName + " " + _?.lastName;
                          })}
                          autoHighlight
                          onChange={(event, value) => {
                            const _u = users.find((_) => {
                              return value === _?.firstName + " " + _?.lastName;
                            });
                            console.log(value, "Val", _u);
                            if (
                              _u?.firstName?.length > 0 &&
                              _u?.lastName?.length > 0 &&
                              _u?.phone?.length > 0
                            ) {
                              setManualUsers((prevUsers) => [...prevUsers, _u]);
                            }
                            setUsers([]);
                          }}
                          getOptionLabel={(option) => option}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              {option}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select users"
                              onChange={(e) => _getAllUsers(e.target.value)}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                      </Grid> */}
                      <Grid item xs={12}>
                        <Box className="main-btn-sec">
                          <Button
                            primary
                            variant="contained"
                            className="main-btn-sec"
                            // className="main-btn"
                            endIcon={<AddCircleIcon />}
                            onClick={handleAddUsersOpen}
                          >
                            Add Parties
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        {manualUsers?.length > 0
                          ? manualUsers?.map((user, index) => {
                            return (
                              <Button
                                key={index}
                                variant="contained"
                                // className="main-btn"
                                sx={{
                                  m: 1,
                                  borderRadius: 50,
                                  backgroundColor: "#27447c",
                                  "&:hover": {
                                    backgroundColor: "#c1c1c1",
                                  },
                                }}
                                endIcon={<ClearIcon />}
                                onClick={() => handleRemoveUser(index)}
                              >
                                {user?.firstName + " " + user?.lastName}
                              </Button>
                            );
                          })
                          : null}
                      </Grid>
                      {/* Modal starts */}
                      <Modal
                        disableBackdropClick
                        open={open}
                        oa-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box>
                          <form
                            onSubmit={() => { }}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                              gap: "16px",
                              padding: "16px",
                            }}
                          >
                            <Typography variant="h6">Confirm Upload</Typography>
                            <Typography color="textSecondary">
                              once you'll upload the document you'll not be able
                              to change it
                            </Typography>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="secondary"
                                    name="saveAddress"
                                    onClick={(e) => {
                                      setConsent(e.target.checked);
                                    }}
                                  />
                                }
                                label="Mark to confirm the Documents"
                              />
                            </div>
                            <div
                              style={{
                                alignSelf: "end",
                                display: "flex",
                                gap: "16px",
                              }}
                            >
                              <Button
                                onClick={
                                  // handleClose();
                                  confirmHandleClose
                                }
                              >
                                Cancel
                              </Button>
                              {!documentData.consent ? (
                                <Button
                                  disabled={true}
                                  variant="contained"
                                  onClick={() => {
                                    _addNotary();
                                    setOpen(false);
                                  }}
                                >
                                  Upload
                                </Button>
                              ) : (
                                <Button
                                  disabled={false}
                                  variant="contained"
                                  onClick={() => {
                                    _addNotary();
                                    setOpen(false);
                                  }}
                                >
                                  Upload
                                </Button>
                              )}
                            </div>
                          </form>
                        </Box>
                      </Modal>
                      {/* Modal Ends */}
                    </Grid>
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions className="p-0">
                <Box className="main-btn-sec">
                  <Button
                    primary
                    variant="contained"
                    // className="main-btn"
                    onClick={() => {
                      handleOpen();
                    }}
                  >
                    Next
                  </Button>
                </Box>
              </DialogActions>
            </Dialog>
            {/* Add Notary Modal  close*/}
            <Dialog
              open={confirmOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="import-steps-modal confirm-upload-modal"
            >
              <DialogTitle id="alert-dialog-title" className="modal-title">
                {"Confirm Upload"}
              </DialogTitle>
              <DialogContent className="modal-body">
                <Box id="alert-dialog-description" className="modal-content">
                  <form
                    onSubmit={() => { }}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      // gap: "16px",
                      // padding: "16px",
                    }}
                  >
                    {/* <Typography variant="h6">Confirm Upload</Typography> */}
                    <Typography color="textSecondary">
                      once you'll upload the document you'll not be able to
                      change it
                    </Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        className="modal-cus-check"
                        control={
                          <Checkbox
                            color="primary"
                            name="saveAddress"
                            onClick={(e) => {
                              setConsent(e.target.checked);
                            }}
                          />
                        }
                        label="Mark to confirm the Documents"
                      />
                    </div>
                    <div
                      style={{ alignSelf: "end", display: "flex", gap: "16px" }}
                    >
                      <div className="main-btn-sec">
                        <Button
                          secondary
                          onClick={() => {
                            confirmHandleClose();
                          }}
                        >
                          Cancel
                        </Button>
                        {!documentData.consent ? (
                          <Button
                            primary
                            disabled={true}
                            variant="contained"
                            onClick={() => {
                              _addNotary();
                            }}
                          >
                            Upload
                          </Button>
                        ) : (
                          <Button
                            disabled={false}
                            // variant="contained"
                            primary
                            onClick={() => {
                              _addNotary();
                            }}
                          >
                            Upload
                          </Button>
                        )}
                      </div>
                    </div>
                  </form>
                </Box>
              </DialogContent>
            </Dialog>
            {/* description modal starts */}
            <Dialog
              open={desc}
              onClose={handleDescClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="import-steps-modal"
              fullWidth
            >
              <DialogTitle id="alert-dialog-title" className="modal-title">
                {"Description"}
              </DialogTitle>
              <DialogContent className="modal-body">
                <Box id="alert-dialog-description" className="modal-content">
                  <Typography variant="p" component="p">
                    {" "}
                    {descData}
                  </Typography>
                </Box>
              </DialogContent>
              <DialogActions className="p-0">
                <Box className="main-btn-sec">
                  <Button primary onClick={handleDescClose}>
                    close
                  </Button>
                </Box>
              </DialogActions>
            </Dialog>
            {/* description modal ends */}
          </Box>
        </div>
      )}
    </>
  );
};

export default Index;
