import React, { useEffect } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { getSingleNotaryBase } from "../../../../ApiHelpers/notaryCalls";
import { Triangle } from "react-loader-spinner";
import { toast } from "react-toastify";
import { loginWithOTP } from "../../../../redux/features/slices/user/UserService";
import { useDispatch } from "react-redux";

const ContactVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [docData, setDocData] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const getQueryParam = (param) => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(param);
  };

  // Extract the 'id' query parameter
  const id = getQueryParam("id");
  console.log("data", id);
  const contactData = [
    { contactNumber: "1234567890" },
    { contactNumber: "1234567890" },
    { contactNumber: "1234567890" },
    { contactNumber: "1234567890" },
  ];

  const dispatch = useDispatch();
  const sendOtp = async (contact) => {
    const data = new URLSearchParams();
    data.append("id", contact?.userId);
    dispatch(loginWithOTP(data)).then((res) => {
      if (res?.payload?.status == 200) {
        toast.success(res?.payload?.message);
        navigate("/access-document/verify/", {
          state: {
            contactNumber: contact?.phone,
            docId: id,
            userId: contact?.userId,
          },
        });
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await getSingleNotaryBase(id);
        console.log("LLLOO", data);
        setDocData(data?.data?.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  console.log(docData, "docDatadocData");

  return (
    <Index.Box className="user-contact-main">
      <Index.Box className="auth-contact-main">
        {loading ? (
          <div style={{
            marginTop:"18%",
            display: "flex",
            justifyContent: "center",
            aligItems: "center"
          }}>
            <div>
              <Triangle
                visible={true}
                height="80"
                width="80"
                color="#1976d2"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          </div>
        ) : <Index.Box className="auth-contact-right">
          {docData !== null ? (
            <Index.Box className="auth-contact-common-box">
              <Index.Box className="auth-contact-header">
                <Index.Box className="auth-contact-title">
                  Verify Contact Number to access your document
                </Index.Box>
              </Index.Box>
              <Index.Box className="user-card-box">
                <Index.Box className="">
                  <Index.Box
                    className="user-card-img-main"
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <img
                      src={PagesIndex.Svg.Document}
                      // className="card-img"
                      alt=""
                      style={{ width: "60px" }}
                    />
                    <Index.Typography
                      className="card-user-title"
                      component="p"
                      variant="p"
                      sx={{
                        width: "80%",
                        textAlign: "start",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <span>{docData?.title}</span>
                    </Index.Typography>
                  </Index.Box>

                  <Index.Box className="card-containt-main">
                    <Index.Box className="card-data-flex">
                      {/* <Index.Typography
                      className="card-user-lable"
                      component="p"
                      variant="p"
                    >
                      Description :
                    </Index.Typography> */}
                      <Index.Box
                        className="card-user-data"
                        component="p"
                        variant="p"
                        // code to make this div fixed size and scrollable
                        sx={{
                          height: "180px",
                          overflow: "auto",
                          border: "1px solid #d9d9d9",
                          borderRadius: "8px",
                          padding: "10px",
                          width: "97%",
                        }}
                      >
                        {docData?.description}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box
                className="serial-wise-number"
                sx={{ marginY: "20px", fontWeight:"bolder", fontSize:"20px" }}
              >
                Download Document
              </Index.Box>
              <Index.Box
                className="card-user-data"
                component="p"
                variant="p"
                sx={{ marginY: "20px" }}
              >
                verify one of the bellow phone numbers and download the document
              </Index.Box>
              <Index.Box
                className="serial-wise-contact"
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  height: "100px",
                  overflow: "auto",
                }}
              >
                {docData?.candidate?.map((contact, index) => (
                  <>
                    {contact?.phone !== "undefined" &&
                      contact?.phone !== undefined ? (
                      <Index.Box
                        sx={{
                          marginTop: "10px",
                          width: "30%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Index.Box>
                          {index + 1} {")"}
                        </Index.Box>
                        <Index.Box
                          className="card-number-user-data"
                          component="p"
                          variant="p"
                        >
                          {/* {contact.maskedNumber} */}
                          {/* {contact?.phone} */}
                          {`${contact?.phone?.substring(0, 2)}${"*".repeat(
                            contact?.phone?.length - 4
                          )}${contact?.phone?.substring(
                            contact?.phone?.length - 2
                          )}`}
                        </Index.Box>
                        <Index.Button
                          className="verify-contact-button"
                          onClick={() => {
                            sendOtp(contact);
                          }}
                        >
                          Verify
                        </Index.Button>
                      </Index.Box>
                    ) : null}
                  </>
                ))}
              </Index.Box>
            </Index.Box>
          ) : (
            <Index.Box className="auth-contact-header">
              <Index.Box className="auth-contact-title">
                Data not found
              </Index.Box>
            </Index.Box>
          )}
        </Index.Box>}
      </Index.Box>
    </Index.Box>
  );
};

export default ContactVerification;
