import React, { useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

export default function UserResetPassword() {
  const navigate = PagesIndex.useNavigate();
  const location = useLocation();
  // password
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //confirm password
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Please enter your password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/,
        "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
      ),
    confirmPassword: Yup.string()
      .required("Please enter your confirm password")
      .oneOf(
        [Yup.ref("password")],
        "Password and confirm passwords do not match"
      ),
  });
  const handleFormSubmit = async (values) => {
    console.log(values, "values33");
    const data = {
      otp: location?.state?.row,
      newPassword: values.password,
      confirmPassword: values.confirmPassword,
    };
    await DataService.post(API.User.RESET_PASSWORD, data)
      .then((res) => {
        if (res?.data?.status == 200) {
          toast.success(res?.data?.message);
          navigate("/");
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <Index.Box className="admin-main">
      <Index.Box className="auth-main">
        <Index.Box className="auth-left">
          {/* <PagesIndex.Svg.CompanyLogo /> */}
        </Index.Box>
        <Index.Box className="auth-right">
          <Index.Box className="auth-common-box">
            <Index.Box className="auth-header">
              <Index.Box className="auth-title">Reset Password</Index.Box>
              <Index.Box className="auth-subtitle">
                Please enter Password and confirm password
              </Index.Box>
            </Index.Box>
            <Formik
              initialValues={initialValues}
              onSubmit={handleFormSubmit}
              validationSchema={validationSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                setFieldValue,
                handleSubmit,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Index.Box className="auth-body">
                    <Index.Grid container spacing={3}>
                      <Index.Grid item xs={12}>
                        <Index.Box className="form-group">
                          <Index.FormHelperText className="form-lable">
                            Password
                          </Index.FormHelperText>
                          <Index.OutlinedInput
                            fullWidth
                            className="form-input"
                            placeholder="Please enter your password"
                            autocomplete="off"
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={values?.password}
                            onChange={(e) => {
                              setFieldValue("password", e.target.value.trim());
                            }}
                            onBlur={handleBlur}
                            endAdornment={
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                          />
                        </Index.Box>
                        {errors.password && touched.password && (
                          <p className="error-text">{errors.password}</p>
                        )}
                      </Index.Grid>
                      <Index.Grid item xs={12}>
                        <Index.Box className="form-group">
                          <Index.FormHelperText className="form-lable">
                            Confirm Password
                          </Index.FormHelperText>
                          {/* <Index.TextField
                            fullWidth
                            type="password"
                            id="resetConfirmPassword"
                            name="resetConfirmPassword"
                            className="form-input"
                            placeholder="Please enter your confirm password"
                          /> */}
                          <Index.OutlinedInput
                            fullWidth
                            className="form-input"
                            placeholder="Please enter your confirm password"
                            autocomplete="off"
                            id="outlined-adornment-password"
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirmPassword"
                            value={values?.confirmPassword}
                            onChange={(e) => {
                              setFieldValue(
                                "confirmPassword",
                                e.target.value.trim()
                              );
                            }}
                            onBlur={handleBlur}
                            endAdornment={
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowConfirmPassword}
                                  onMouseDown={handleMouseDownConfirmPassword}
                                  edge="end"
                                >
                                  {showConfirmPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                          />
                        </Index.Box>
                        {errors.confirmPassword && touched.confirmPassword && (
                          <p className="error-text">{errors.confirmPassword}</p>
                        )}
                      </Index.Grid>
                      <Index.Grid item xs={12}>
                        <Index.Box className="auth-btn-box">
                          <Index.Link to="/" className="auth-link">
                            Cancel
                          </Index.Link>
                          <Index.Box className="main-btn-sec">
                          <PagesIndex.Button
                            primary
                            // onClick={() => {
                            //   navigate("/admin/dashboard");
                            // }}
                            type="submit"
                          >
                            Submit
                          </PagesIndex.Button>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>
                  </Index.Box>
                </Form>
              )}
            </Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
