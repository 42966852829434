import SettingIcon from "./images/svg/SettingIcon";
import ErrorImage from "./images/svg/ErrorImage";
import CompanyLogo from "./images/svg/CompanyLogo";
import NotificationIcon from "./images/svg/NotificationIcon";
import HumburgerIcon from "./images/svg/HumburgerIcon";
import CloseIcon from "./images/svg/CloseIcon";
import DashboardIcon from "./images/svg/DashboardIcon";
import UserAddIcon from "./images/svg/UserAddIcon";
import TotalUserIcon from "./images/svg/TotalUserIcon";
import TotalBuyerIcon from "./images/svg/TotalBuyerIcon";
import TotalSellerIcon from "./images/svg/TotalSellerIcon";
import TotalIncomeIcon from "./images/svg/TotalIncomeIcon";
import ArrowBackIcon from "./images/svg/ArrowBackIcon";
import Document from "./images/svg/document.svg"

const Svg = {
  SettingIcon,
  ErrorImage,
  CompanyLogo,
  NotificationIcon,
  HumburgerIcon,
  CloseIcon,
  DashboardIcon,
  UserAddIcon,
  TotalUserIcon,
  TotalBuyerIcon,
  TotalSellerIcon,
  TotalIncomeIcon,
  ArrowBackIcon,
  Document,
};

export default Svg;
