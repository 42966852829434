import React from 'react'

export default function TotalSellerIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="37.5" viewBox="0 0 50 37.5">
            <g id="__TEMP__SVG__" transform="translate(0 -6.25)">
                <g id="Group_19" data-name="Group 19">
                    <path id="Path_16" data-name="Path 16" d="M18.75,25a9.375,9.375,0,1,0-9.375-9.375A9.375,9.375,0,0,0,18.75,25ZM3.125,43.75A3.061,3.061,0,0,1,0,40.625c0-3.125,3.125-12.5,18.75-12.5S37.5,37.5,37.5,40.625a3.061,3.061,0,0,1-3.125,3.125Zm31.25-32.812a1.562,1.562,0,0,1,1.563-1.562h12.5a1.563,1.563,0,0,1,0,3.125h-12.5A1.562,1.562,0,0,1,34.375,10.938Zm1.563,7.813a1.563,1.563,0,0,0,0,3.125h12.5a1.563,1.563,0,0,0,0-3.125Zm6.25,9.375a1.562,1.562,0,0,0,0,3.125h6.25a1.563,1.563,0,0,0,0-3.125Zm0,9.375a1.563,1.563,0,0,0,0,3.125h6.25a1.563,1.563,0,0,0,0-3.125Z" fill="#cc6b16" />
                </g>
            </g>
        </svg>
    )
}

