import React from "react";
import Index from "../../../Index";
import { useLocation, useNavigate } from "react-router-dom";
import PagesIndex from "../../../PagesIndex";
import { MuiOtpInput } from "mui-one-time-password-input";
import { getSingleNotaryAdvanced } from "../../../../ApiHelpers/notaryCalls";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  getSingleUser,
  loginWithOTP,
  verifyOTP,
} from "../../../../redux/features/slices/user/UserService";

const VerifyContactOTP = () => {
  const location = useLocation();
  const data = location.state;
  const navigate = useNavigate();
  const [otp, setOtp] = React.useState("");
  const [sendingOTP, setSendingOTP] = React.useState(false);

  const dispatch = useDispatch();
  console.log(data, "locationlocation");
  const handleChange = (otpValue) => {
    const numericValue = otpValue.replace(/[^0-9]/g, "");
    if (!isNaN(numericValue)) {
      setOtp("otp", numericValue);
    }
  };

  const sendOtp = async (contact) => {
    const _data = new URLSearchParams();
    _data.append("id", data?.userId);
    dispatch(loginWithOTP(_data)).then((res) => {
      if (res?.payload?.status == 200) {
        toast.success(res?.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const openDocument = async () => {
    try {
      // let userData = ({
      //   "id": data?.userId
      // });
      // dispatch(getSingleUser(userData)).then(async(res) => {
      //   if (res?.payload?.status == 200) {
      //     console.log(res?.payload?.data, "res?.payload?.data?.phone")
      //     localStorage.setItem("phone", res?.payload?.data?.phone);
      //     toast.success(res?.payload?.message);
      //     setOtp("");
      //     const doc = await getSingleNotaryAdvanced(data.docId);
      //     window.open(doc?.data?.data?.image, "_blank");
      //     console.log(doc?.data?.data?.image, "Hello")
      //   } else {
      //     toast.error(res?.payload?.message);
      //     setOtp("");
      //   }
      // });
      let otpData = {
        otp: otp,
        id: data?.userId,
      };
      dispatch(verifyOTP(otpData)).then(async (res) => {
        if (res?.payload?.status == 200) {
          console.log(res?.payload?.data, "res?.payload?.data?.phone");
          localStorage.setItem("phone", res?.payload?.data?.phone);
          toast.success(res?.payload?.message);
          setOtp("");
          const doc = await getSingleNotaryAdvanced(data.docId);
          window.open(doc?.data?.data?.image, "_blank");
          console.log(doc?.data?.data?.image, "Hello");
        } else {
          toast.error(res?.payload?.message);
          setSendingOTP(true);
          setOtp("");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  console.log(data, "Hello");
  return (
    <Index.Box className="user-otp-main">
      <Index.Box className="auth-otp-main">
        <Index.Box className="auth-otp-left"></Index.Box>
        <Index.Box className="auth-otp-right">
          <Index.Box className="auth-otp-common-box">
            <Index.Box className="auth-otp-header">
              <Index.Box className="auth-otp-title">Enter OTP sent to {data.contactNumber}</Index.Box>
            </Index.Box>
            <Index.Box className="auth-otp-body">
              <Index.Grid container spacing={3}>
                <Index.Grid item xs={12}>
                  {/* {data.contactNumber ? (
                    <Index.Box
                      className="auth-otp-body"
                      sx={{ textAlign: "center" }}
                    >
                      {"otp sent to "}{data.contactNumber}
                    </Index.Box>
                  ) : (
                    <Index.Box
                      className="auth-otp-body"
                      sx={{ textAlign: "center" }}
                    >
                      "**********"
                    </Index.Box>
                  )} */}
                </Index.Grid>
                <Index.Grid item xs={12}>
                  <MuiOtpInput
                    value={otp}
                    length={6}
                    onChange={(otpValue) => {
                      const numericValue = otpValue.replace(/[^0-9]/g, "");
                      if (!isNaN(numericValue)) {
                        setOtp(numericValue);
                      }
                    }}
                  />
                </Index.Grid>
                <Index.Grid item xs={12} sx={{ textAlign: "center" }}>
                  {sendingOTP ? (
                    <Index.Typography
                      style={{ textAlign: "right" }}
                      className="dont-modal-para"
                      component="p"
                      variant="p"
                    >
                      Didn't received OTP?{" "}
                      <Index.Button
                        className="auth-redirect-link"
                        onClick={() => {
                          sendOtp();
                        }}
                        sx={{ textTransform: "none" }}
                      >
                        Resend OTP
                      </Index.Button>
                    </Index.Typography>
                  ) : null}
                  <Index.Box className="verify-otp-box">
                    <Index.Button
                      disabled={otp.length < 6}
                      className="verify-otp-button"
                      onClick={() => {
                        openDocument();
                      }}
                    >
                      Verify & Download Document
                    </Index.Button>
                  </Index.Box>
                </Index.Grid>
              </Index.Grid>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default VerifyContactOTP;
