import { GET_SINGLE_USER } from "../ApiHelpers/apiRoutes";

const API = {
  Admin: {},
  User: {
    USER_REGISTER: "user/register",
    USER_LOGIN: "/user/user-login",
    FORGOT_PASSWORD: "/user/forgot-password",
    GET_ALL_STATE: "/user/state-list",
    OTP_VERIFY: "/user/verify-otp",
    RESET_PASSWORD: "/user/reset-password",
    NOTARY_USER_WISE:"/notary/user-wise",
    USER_OTP_LOGIN: "user/otp-login",
    USER_OTP_VERIFY:"user/verify-otp-login",
    GET_SINGLE_USER
  },
  Notary: {},
};

export { API };
