import React from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'

export default function ForgotPassword() {
  return (
    <Index.Box className="admin-main">
      <Index.Box className="auth-main">
        <Index.Box className="auth-left">
          {/* <PagesIndex.Svg.CompanyLogo /> */}
        </Index.Box>
        <Index.Box className="auth-right">
          <Index.Box className="auth-common-box">
            <Index.Box className="auth-header">
              <Index.Box className="auth-title">
                Forgot Password
              </Index.Box>
              <Index.Box className="auth-subtitle">
                Please check your email to Reset Password
              </Index.Box>
            </Index.Box>
            <Index.Box className="auth-body">
              <Index.Grid container spacing={3}>
                <Index.Grid item xs={12}>
                  <Index.Box className="form-group">
                    <Index.FormHelperText className="form-lable">
                      Email
                    </Index.FormHelperText>
                    <Index.TextField
                      fullWidth
                      type="email"
                      id="email"
                      name="email"
                      className="form-input"
                      placeholder="Please enter your email"
                    />
                  </Index.Box>
                </Index.Grid>
                <Index.Grid item xs={12}>
                  <Index.Box className="auth-btn-box">
                    <Index.Link to="/admin" className='auth-link'>
                      <PagesIndex.Svg.ArrowBackIcon />
                      Back to login
                    </Index.Link>
                    {/* <PagesIndex.Button primary>
                      Send OTP
                    </PagesIndex.Button> */}
                    <Index.Box className="main-btn-sec">
                    <Index.Link to="/admin/verify-otp" className='btn btn-primary'>
                      Send OTP
                    </Index.Link>
                    </Index.Box>
                  </Index.Box>
                </Index.Grid>
              </Index.Grid>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  )
}
