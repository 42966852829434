import React, { useState } from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'

export default function OtpVerify() {

  const [otp, setOtp] = useState('');

  return (
    <Index.Box className="admin-main">
      <Index.Box className="auth-main">
        <Index.Box className="auth-left">
          {/* <PagesIndex.Svg.CompanyLogo /> */}
        </Index.Box>
        <Index.Box className="auth-right">
          <Index.Box className="auth-common-box">
            <Index.Box className="auth-header">
              <Index.Box className="auth-title">
                OTP
              </Index.Box>
              <Index.Box className="auth-subtitle">
                Please enter Otp
              </Index.Box>
            </Index.Box>
            <Index.Box className="auth-body">
              <Index.Grid container spacing={3}>
                <Index.Grid item xs={12}>
                  <Index.Box className="form-group">
                    <PagesIndex.OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      renderInput={(props) => <input {...props} />}
                      skipDefaultStyles
                      containerStyle="form-input otp-input-box"
                    />
                  </Index.Box>
                </Index.Grid>
                <Index.Grid item xs={12}>
                  <Index.Box className="auth-btn-box">
                    <Index.Link to="/admin/forgot-password" className='auth-link'>
                      <PagesIndex.Svg.ArrowBackIcon />
                      Back
                    </Index.Link>
                    {/* <PagesIndex.Button primary>
                      Verify OTP
                    </PagesIndex.Button> */}
                    <Index.Box className="main-btn-sec">
                    <Index.Link to="/admin/reset-password" className='btn btn-primary'>
                      Verify OTP
                    </Index.Link>
                    </Index.Box>
                  </Index.Box>
                </Index.Grid>
              </Index.Grid>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  )
}
