import React from 'react'

export default function TotalBuyerIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="49.844" height="50" viewBox="0 0 49.844 50">
            <g id="__TEMP__SVG__" transform="translate(0 -2.96)">
                <g id="Group_17" data-name="Group 17">
                    <path id="Path_14" data-name="Path 14" d="M24.922,2.96l20.592,9.5h2.773a1.558,1.558,0,0,1,1.558,1.558v6.231a1.558,1.558,0,0,1-1.558,1.558H46.729V43.614a1.558,1.558,0,0,1,1.511,1.181L49.8,51.025a1.558,1.558,0,0,1-1.511,1.935H1.558A1.558,1.558,0,0,1,.047,51.025L1.6,44.794a1.558,1.558,0,0,1,1.511-1.181V21.807H1.558A1.558,1.558,0,0,1,0,20.249V14.019a1.558,1.558,0,0,1,1.558-1.558H4.33Zm-13.159,9.5H38.078L24.922,6.386ZM6.231,21.807V43.614H9.346V21.807Zm6.231,0V43.614h7.788V21.807Zm10.9,0V43.614H26.48V21.807Zm6.231,0V43.614h7.788V21.807Zm10.9,0V43.614h3.115V21.807Zm6.231-3.115V15.576H3.115v3.115ZM45.514,46.729H4.33l-.779,3.115H46.293l-.779-3.115Z" fill="#196dce" />
                </g>
            </g>
        </svg>
    )
}

