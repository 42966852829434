// service
import { createAsyncThunk } from "@reduxjs/toolkit";
import PageIndex from "../../../../container/PagesIndex";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { toast } from "react-toastify";
import { GET_SINGLE_USER } from "../../../../ApiHelpers/apiRoutes";

// user login
export const userLogin = createAsyncThunk("user/userLogin", async (data) => {
  try {
    const response = await DataService.post(API.User.USER_LOGIN, data);
    console.log(response.data?._id,"Login Response");
    console.log(response.data?.token?.token,"Login Response");
    localStorage.setItem("token", response.data?.token?.token);
    localStorage.setItem("_id", response.data?._id);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});

// user forgot password

export const userForgotPassword = createAsyncThunk(
  "user/UserForgotPassword",
  async (data) => {
    // try {
    //   const response = await DataService.post(API.User.USER_LOGIN, data);
    //   return response.data;
    // } catch (error) {
    //   toast.error(error?.response?.data?.message);
    // }
  }
);

// get all india state

export const getState = createAsyncThunk(
  "user/getState",
  async (data) => {
    try {
      const response = await DataService.get(API.User.GET_ALL_STATE);
      return response.data;
    } catch (error) {}
  }
);

// login with otp
export const loginWithOTP = createAsyncThunk("user/userLogin", async (data) => {
  try {
    const response = await DataService.post(API.User.USER_OTP_LOGIN, data);
    console.log(response.data?._id,"Login Response");
    console.log(response.data?.token?.token,"Login Response");
    localStorage.setItem("token", response.data?.token?.token);
    localStorage.setItem("_id", response.data?._id);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});

// verify otp
export const verifyOTP = createAsyncThunk("user/userLogin", async (data) => {
  try {
    const response = await DataService.post(API.User.USER_OTP_VERIFY, data);
    console.log(response.data?._id,"Login Response");
    console.log(response.data?.token?.token,"Login Response");
    localStorage.setItem("token", response.data?.token?.token);
    localStorage.setItem("_id", response.data?._id);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});

// get single user
export const getSingleUser = createAsyncThunk("user/single-user", async (data) => {
  try {
    const response = await DataService.get(GET_SINGLE_USER+data?.id);
    console.log(response.data?._id,"Login Response");
    console.log(response.data?.token?.token,"Login Response");
    localStorage.setItem("token", response.data?.token?.token);
    localStorage.setItem("_id", response.data?._id);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});