import React from 'react'

export default function TotalUserIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="37.498" viewBox="0 0 50 37.498">
            <g id="person-lines-fill" transform="translate(0 -6.25)">
                <g id="Group_16" data-name="Group 16">
                    <path id="Path_13" data-name="Path 13" d="M18.75,6.25a1.563,1.563,0,0,1,1.469,1.031L31.25,37.612l4.781-13.15A1.562,1.562,0,0,1,37.5,23.438H48.438a1.562,1.562,0,1,1,0,3.125H38.594L32.719,42.719a1.563,1.563,0,0,1-2.938,0L18.75,12.387,13.969,25.534A1.563,1.563,0,0,1,12.5,26.562H1.563a1.562,1.562,0,1,1,0-3.125h9.844L17.281,7.281A1.562,1.562,0,0,1,18.75,6.25Z" fill="#b13082" fillRule="evenodd" />
                </g>
            </g>
        </svg>
    )
}

