import axios from "axios";
import {
  ADD_NOTARY,
  GET_ADVOCATE_WISE,
  GET_ALL_USERS,
  GET_SINGLE_NOTARY_BASIC,
  GET_SINGLE_NOTARY_ADVANCED,
  GET_USER_NOTARIES,
  UPDATE_NOTARY,
} from "./apiRoutes";
import { API_ENDPOINT } from "../config/DataService";

const addNotary = async (
  _notaryId,
  _title,
  _document,
  _candidates,
  _description
) => {
  try {
    let data = new FormData();
    data.append("notaryId", _notaryId);
    data.append("title", _title);
    data.append("document", _document);
    for (let i = 0; i < _candidates.length; i++) {
      data.append(`candidate[${i}][firstName]`, _candidates[i]?.firstName);
      data.append(`candidate[${i}][lastName]`, _candidates[i]?.lastName);
      data.append(`candidate[${i}][phone]`, _candidates[i]?.phone);
    }
    data.append("description", _description);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: API_ENDPOINT + ADD_NOTARY,
      headers: {
        auth: localStorage.getItem("token"),
      },
      data: data,
    };
    const response = await axios.request(config);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const updateNotary = async (_id, _tokenId) => {
  try {
    let data = JSON.stringify({
      id: _id,
      tokenId: _tokenId,
    });

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: API_ENDPOINT + UPDATE_NOTARY,
      headers: {
        auth: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };
    const response = await axios.request(config);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getAllUsers = async (key) => {
  try {
    let data = JSON.stringify({
      searchKey: key,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: API_ENDPOINT + GET_ALL_USERS,
      headers: {},
      data: data,
    };
    const response = await axios.request(config);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getAllAdvocateNotaries = async (_advocateId) => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url:
        API_ENDPOINT +
        GET_ADVOCATE_WISE +
        _advocateId,
      headers: {},
    };
    const response = await axios.request(config);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};


const getAllUsersNotaries = async (_phoneNumber) => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url:
        API_ENDPOINT +
        GET_USER_NOTARIES +
        _phoneNumber,
      headers: {},
    };
    const response = await axios.request(config);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getSingleNotaryBase = async (_id) => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url:
        API_ENDPOINT +
        GET_SINGLE_NOTARY_BASIC+
        _id,
      headers: {},
    };
    const response = await axios.request(config);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getSingleNotaryAdvanced = async (_id) => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url:
        API_ENDPOINT +
        GET_SINGLE_NOTARY_ADVANCED+
        _id,
      headers: {},
    };
    const response = await axios.request(config);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export { addNotary, getAllUsers, getAllAdvocateNotaries, updateNotary, getAllUsersNotaries, getSingleNotaryBase,getSingleNotaryAdvanced};
