import React from 'react'
import Index from '../../../../component/Index'
import PagesIndex from '../../../../component/PagesIndex'
import './adminDashboard.css'
import './adminDashboard.responsive.css'

export default function AdminDashboard() {

  const counterItem = [
    {
      title: "Total Users",
      number: "1325M",
      icon: <PagesIndex.Svg.TotalUserIcon />,
    },
    {
      title: "Total Buyers",
      number: "547K",
      icon: <PagesIndex.Svg.TotalBuyerIcon />,
    },
    {
      title: "Total Sellers",
      number: "12.3K",
      icon: <PagesIndex.Svg.TotalSellerIcon />,
    },
    {
      title: "Total Income",
      number: "985K",
      icon: <PagesIndex.Svg.TotalIncomeIcon />,
    },
  ]

  return (
    <Index.Box className="dashboard-main">
      <Index.Box className="dahboard-common-header">
        <Index.Typography className='header-title'>
          Dashboard
        </Index.Typography>
      </Index.Box>
      <Index.Box className="total-counter-box">
        {counterItem.map((item, key) => (
          <Index.Box key={key} className="total-counter-item">
            <Index.Box className="counter-item-left">
              <Index.Typography className='counter-title'>
                {item.title}
              </Index.Typography>
              <Index.Typography className='counter-number'>
                {item.number}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="counter-item-right">
              {item.icon}
            </Index.Box>
          </Index.Box>
        ))}
      </Index.Box>
    </Index.Box>
  )
}
