import React from 'react'
import Index from '../../Index'

export default function Footer() {
  return (
    <Index.Box>
      Footer
    </Index.Box>
  )
}
